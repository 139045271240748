import React, { Component, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MapContainer  from './MapContainer';
import toast, { Toaster } from 'react-hot-toast';
import {Timeline, TimelineEvent} from 'react-event-timeline'

class AddgroundCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],desc:'',
            productDesc:'',
            price:'',
            priceName:'',
            priceMail:'',
            pricePhone:'',
            price:'',
            ratingName:'',
            ratingMail:'',
            ratingPhone:'',
            rating:'',
            asn:'',
            eural:'',
            nameProject:'',
            locationProject: '',
            postalProject:'',
            amountTon:'',
            groundClass:'',
            comingFrom: '',
            information: '',
            date:'',
            contactPerson:'',
            attachment:'',
            brl9335:'',
            ratingStatus:'',
            username:'',
            commentText:'',
            status:[],
            priceAttachment:'',
            tempUrlPrice:'',
            priceNameOfferte:'',
            nameAsn:'',
            groundClassIntern:'',
            ratingText:'',
            priceNavision:'',
            pre:'',
            lat:'',
            long:'',
            url:'',
            postalLocated:'',
            streetLocated:'',
            nameProject:'',
            postal:'',
            street:'',
            name:'',
        };
    
    }
    

   
    


    componentDidMount() {

   


    }

    UpdateRating(){
        

        if(this.state.rating === 'goedgekeurd'){
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/rating').update({
   
                check: this.state.rating ==='goedgekeurd' ? 'agreed': 'canceled',
                rating: this.state.rating,
                name:this.state.username,
                nameAsn:this.state.nameAsn != ''? this.state.nameAsn:' ',
                groundClassIntern:this.state.groundClassIntern != ''? this.state.groundClassIntern:' ',
                ratingText:this.state.ratingText != ''? this.state.ratingText:' ',
                brl9335:this.state.brl9335  != ''? this.state.brl9335:' ',
                pre:this.state.pre!= ''? this.state.pre:' '
                
            
        }).then(()=>{
            this.addComment('description');
             let headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','http://localhost:3000');
            //headers.append('Origin','https://frontend-test-bbo.web.app/')
            headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
            fetch("https://us-central1-dirix-bv.cloudfunctions.net/CheckTwoSubmit", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
              })
              .then(res => {
                toast.success('Beoordeling is opgeslagen');
              })
        })

        }else{

            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/rating').update({
   
                check: this.state.rating ==='goedgekeurd' ? 'agreed': 'canceled',
                rating: this.state.rating,
                name:this.state.username,
                nameAsn:this.state.nameAsn != ''? this.state.nameAsn:' ',
                groundClassIntern:this.state.groundClassIntern != ''? this.state.groundClassIntern:' ',
                ratingText:this.state.ratingText != ''? this.state.ratingText:' ',
                brl9335:this.state.brl9335  != ''? this.state.brl9335:' ',
                pre:this.state.pre!= ''? this.state.pre:' '
                
            
        }).then(()=>{
            this.addComment('description');
            
        })
        }
   
    }

    UpdatePrice(){
        if(this.state.price === 'goedgekeurd'){
            console.log('mail voor aanvrager wordt verstuurd')
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
   
              check: this.state.price ==='goedgekeurd' ? 'agreed': 'canceled',
              rating: this.state.price,
       
              priceNavision: this.state.priceNavision != ''? this.state.priceNavision:'',
              priceName:this.state.priceName  != ''? this.state.priceName:'',
              number: this.state.priceNameOfferte != ''? this.state.priceNameOfferte:'',
              attachment:this.state.attachment,


          
      }).then(()=>{
        this.addComment('euro');
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-dirix-bv.cloudfunctions.net/MailToRos", {
            method: "POST",
            headers: headers,
              body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
          })
          .then(res => {
            toast.success('Prijs is geupdate');
          })
      })

        }else{

   
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
   
              check: this.state.price ==='goedgekeurd' ? 'agreed': 'canceled',
              rating: this.state.price,
              priceNavision: this.state.priceNavision != ''? this.state.priceNavision:'',
              priceName:this.state.priceName  != ''? this.state.priceName:'',
              number: this.state.priceNameOfferte != ''? this.state.priceNameOfferte:'',
              attachment:this.state.attachment,


          
      }).then(()=>{
        this.addComment('euro');
  
      })
    
    }
    }

    addComment(type){
        let today = new Date();
        var key = Firebase.database().ref().child('/ASN/requests/'+this.props.data.data.id+'/status/').push().key;

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/status/'+key).set({
   
            key: key,
            date: today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),
            text: this.state.commentText,
            user: this.state.username,
            type:type
        }).then(()=>{
            this.setState({
                commentText:''
            })
            toast.success('Opmerking opgeslagen')
        })

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
            requestStatus: 'running',
         })
    }

    saveGroundCard = async()=>{

 await this.getAddressCoords();
 setTimeout(() => {
    var key = Firebase.database().ref().child('/ASN/groundCards/').push().key;
    
    Firebase.database().ref('/ASN/groundCards/'+key).set({
            id:key,

            companyAddressOrigin:this.state.streetLocated,
            companyPostalOrigin:this.state.postalLocated,
            attachments: this.state.url,
            lat:this.state.lat,
            long:this.state.long,
            ratingText:this.state.ratingText

          

      
  }).then(()=>{
    toast.success('Gegevens opgeslagen!')
    this.setState({
        id:key,
        companyName:'',
        companyAddress:'',
        companyPostalCity:'',
        contactPersonRequest:'',
        companyNameOrigin:'',
        companyAddressOrigin:'',
        companyPostalOrigin:'',
        attachment: '',
        lat:'',
        long:'',
        ratingText:''
    })
  })
 }, 1000);
   
      
    
    
    }


   

    getAddressCoords(){
        this.setState({
            secondBlock:true
        })
        console.log('here')
        let headers = new Headers();
       
        fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+this.state.streetLocated+" "+this.state.postalLocated+"&key=AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0", {
                method: "POST",
            
                
              })
              .then(res => {
              
                return res.json();
              })
              .then(data => {
                console.log(data.results[0].geometry.location.lat)
                this.setState({
                    lat:data.results[0].geometry.location.lat,
                    long:data.results[0].geometry.location.lng
                })
                
                console.log(data.results[0].geometry.location.lat)
                console.log(data.results[0].geometry.location.lng)
        
                
              })
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


    handleUploadCompanyLogo(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
 
    


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Dirix/attachments/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Dirix/attachments/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                    this.setState({
                        url: url
                    })
                  toast.success('Document opgeslagen');

               
                }).then(()=>{
                     

                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    setLocationName(value){
       
        this.setState({nameProject:value
        })
    }


    render() {
    
        return (
            <div>
                          <Toaster/>
                <Row className="col-sm-12">

                           <Col sm="8" className="request-detail-8">
                           <span>
                        <h3>Locatie van herkomst</h3>


                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Straat + huisnr (geen postbus): </span>
                            <span className="request-detail-col-right align-text-right"><input type="text" placeholder="Straat+ huisnummer" value={this.state.streetLocated} onChange={(event)=>this.setState({streetLocated:event.target.value})}/></span>
                            <hr/>
                           
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Postcode plaats: </span>
                            <span className="request-detail-col-right align-text-right"><input type="text" placeholder="Postcode + plaats" value={this.state.postalLocated} onChange={(event)=>this.setState({postalLocated:event.target.value})}/></span>
                            <hr/>
                            
                        </div></span>
                        
                        <div className="request-detail-row">
                                    <span className="request-detail-col-left">Bijlage toevoegen</span>
                                    <span className="request-detail-col-right align-text-right"><input type="file" onChange={(event) => this.handleUploadCompanyLogo(event)} /></span>
                                    <hr/>
                                </div>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Conclusie</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <textarea className="text-area-backend-form" value={this.state.ratingText} onChange={(event)=>this.setState({ratingText:event.target.value})} />
                                       
                                        </span>
                                    <hr/>
                                </div>
                                <button disabled={this.state.url != '' ?false:true} onClick={()=>this.saveGroundCard()} className="dirix-default-button small-button">Opslaan</button>
                    </Col>
                    <Col sm="4" className="request-detail-4">
          
                       
                       
                       {/*} <Row>
                            <Col sm="12" className="price-block">
                               
                            </Col>
                        </Row>*/}
                        
                        </Col>
                        
                    
                    
                </Row>
            </div>
        );
    }
};

export default AddgroundCardComponent;