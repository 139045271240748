import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';

class GroundCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],
            searchItem:'',
            groundcards:[]
        };
    }



    componentDidMount() {
        this.getRequests();
    }

    getRequests(){
    
        let ref = Firebase.database().ref('/ASN/requests/');
        ref.on('value' , snapshot => {
            this.setState({
                requests:[]
            })
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
         
          console.log(state.attachments ? true :false)
                this.setState(prevState => ({
                    requests: [...prevState.requests, state],
                }))  
            })
        });
        let ground = Firebase.database().ref('/ASN/groundCards/');
        ground.on('value' , snapshot => {
            this.setState({
                groundcards:[]
            })
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
         
          console.log(state.attachments ? true :false)
                this.setState(prevState => ({
                    groundcards: [...prevState.groundcards, state],
                }))  
            })
        });

    }

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12}>
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
        <Col sm={12}>
            <Link className="dirix-default-button " to={'../bodemrapporten/toevoegen'}>Toevoegen analyserapport</Link>
        </Col>
        {this.state.searchItem ?
        <Col sm={12} className="products-table">
        <h3>Zoeken in analyserapporten</h3>
            <table>
                <tr>
                <th>Naam bedrijf</th>
                        <th>Project adres</th>
                        
                        <th>Postcode + Plaats</th>
                        <th>Grondklasse</th>
                        <th>Actie</th>
                </tr>

       {this.state.requests.filter((item) => this.state.searchItem ? item.companyName.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.companyAddress.toLowerCase().includes(this.state.searchItem.toLowerCase())|| item.companyPostalCity.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.groundClass.toLowerCase().includes(this.state.searchItem.toLowerCase()) :item.name == item.name).filter((item)=> item.price.check == 'agreed').filter((item)=> item.rating.check == 'agreed').filter((item)=> item.requestStatus != 'new').filter((item)=> item.attachments != undefined).map((item)=>(
            <tr >
 <td>{item.companyName}</td>
 <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }</td>
                    
                    <td>{item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.groundClass}</td>
                    <th><Link to={{pathname: '/bodemrapporten/details', state:{data: item}}}>Bekijk</Link></th>
            </tr>
       ))}
                   
       
                
                
            </table>
</Col>

        :
            <Col sm={12} className="products-table ">
            <h3>Analyserapporten</h3>
                <table>
                    <tr>
                    <th>Naam bedrijf</th>
                        <th>Project adres</th>
                        
                        <th>Postcode + Plaats</th>
                        <th>Grondklasse</th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests.filter((item)=> item.confirm != undefined ).filter((item)=> item.confirm.asn != undefined ).sort((a, b) =>   b.confirm.asn - a.confirm.asn  ).filter((item)=> item.price.check == 'agreed').filter((item)=> item.rating.check == 'agreed').filter((item)=> item.requestStatus != 'new').filter((item)=> item.attachments != '').map((item)=>(
                <tr >
                  <td>{item.companyName}</td>
 <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }</td>
                    
                    <td>{item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.groundClass}</td>
                    <th><Link to={{pathname: '/bodemrapporten/details', state:{data: item}}}>Bekijk</Link></th>
                </tr>
           ))}

{this.state.groundcards.map((item)=>(
                <tr >
                    <td>{item.companyAddress}</td>
                    <td>{item.companyPostalCity}</td>
                   
                    <td>{item.companyName}</td>
                    <td></td>
                    <th><Link to={{pathname: '/bodemrapporten/details', state:{data: item}}}>Bekijk</Link></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>}
                    
   
            </Row>
            </div>



        );
    }
};

export default GroundCards;