import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon} from 'google-maps-react';

import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const containerStyle = { 
    width: '100%',
    height: '20em',
  }
export class MapContainer extends Component {
    constructor(props) {
        super(props);
   this.state = {
        redirect: false,
        chosenId:'',
        polygon:[]
    }

    this.onClickMap = this.onClickMap.bind(this)
}


    polygonArray =[];

    onClickMap(t, map, coord) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();

      console.log(lat)
      console.log(lng)
      let newLocation = { lat:lat, lng:lng };
      console.log(newLocation)
     // this.polygonArray.push(newLocation)
      //this.polygonArray.push({ lat:lat, lng:lng })
     /* this.setState(prevState => ({
        polygon: [...prevState.polygon, newLocation]
      })) */
      this.setState(prevState =>({
        polygon: [...prevState.polygon,newLocation]
    }))

    localStorage.setItem('markers', JSON.stringify(this.state.polygon))

     
    }

    componentDidMount(){


      setInterval(() => {
       
      if(localStorage.getItem('polygon') != undefined){
        if(localStorage.getItem('polygon') =='reset'){
          this.setState({
            polygon:[]
          })
          localStorage.removeItem('polygon')
        }
      }
      }, 200);

    
      
      
    }

    

  render() {
    return (
      
      <Map 
      
      initialCenter={{
        lat: this.props.lat,
        lng: this.props.lng
      }}
      style={containerStyle} google={this.props.google} zoom={18}
      onClick={this.onClickMap}
      >
     
        <Marker onClick={()=>console.log('clieck')}
                name={'Current location'} />
                 <Marker onClick={()=>console.log('clieck')}
                name={'Current location'} />
        

        <Polygon
        
          paths={this.state.polygon}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#0000FF"
    fillOpacity={0.35} />

   

               

      </Map>
  
     
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0")
})(MapContainer)