import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';

class PriceAgrees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],
            searchItem:''
        };
    }



    componentDidMount() {
        this.getRequests();
    }

    getRequests(){
    
        let ref = Firebase.database().ref('/ASN/requests/');
        ref.on('value' , snapshot => {
            this.setState({
                requests:[]
            })
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
         
          console.log(state.attachments ? true :false)
                this.setState(prevState => ({
                    requests: [...prevState.requests, state],
                }))  
            })
        });

    }

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12}>
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
        {this.state.searchItem ?
        <Col sm={12} className="products-table">
      
            <table>
                <tr>
                        <th>Naam</th>
                        <th>Vestigingsadres</th>
                        <th>Projectadres</th>
                        <th>Offertenummer</th>
                        <th></th>
                        <th>Actie</th>
                </tr>

       {this.state.requests.filter((item)=> item.confirm != undefined ).filter((item)=> item.confirm.asn != undefined ).sort((a, b) =>   b.confirm.asn - a.confirm.asn  ).filter((item) => this.state.searchItem ? item.companyName.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.companyAddress.toLowerCase().includes(this.state.searchItem.toLowerCase())|| item.companyPostalCity.toLowerCase().includes(this.state.searchItem.toLowerCase()) :item.name == item.name).filter((item)=> item.price.check == 'agreed').filter((item)=> item.rating.check == 'agreed').filter((item)=> item.requestStatus != 'new').filter((item)=> item.price.attachment != undefined).map((item)=>(
            <tr >
                <td><b>{item.companyName}</b></td>
                    <td>{item.companyAddress}, {item.companyPostalCity}</td>
                    <td>{item.companyAddressOrigin}, {item.companyPostalOrigin}</td>
                    <td>{item.price.number}</td>
                    <td></td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link></th>
            </tr>
       ))}
                   
       
                
                
            </table>
</Col>

        :
            <Col sm={12} className="products-table">
 
                <table>
                    <tr>
                        <th>Naam</th>
                        <th>Vestigingsadres</th>
                        <th>Projectadres</th>
                        <th>Offertenummer</th>
                        <th></th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests.filter((item)=> item.price.check == 'agreed').filter((item)=> item.rating.check == 'agreed').filter((item)=> item.requestStatus != 'new').filter((item)=> item.price.attachment != undefined).map((item)=>(
                <tr >
                    <td><b>{item.companyName}</b></td>
                    <td>{item.companyAddress}, {item.companyPostalCity}</td>
                    <td>{item.companyAddressOrigin}, {item.companyPostalOrigin}</td>
                    <td>{item.price.number}</td>
                    <td></td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>}
                    
   
            </Row>
            </div>



        );
    }
};

export default PriceAgrees;