import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./Auth";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

/*prod 
var config ={
    apiKey: "AIzaSyB2i07o_A2Hqo9Wk9mV35KcTLmySxoO29U",
    authDomain: "productie-omgeving.firebaseapp.com",
    databaseURL: "https://productie-omgeving-default-rtdb.firebaseio.com",
    projectId: "productie-omgeving",
    storageBucket: "productie-omgeving.appspot.com",
    messagingSenderId: "274979395553",
    appId: "1:274979395553:web:d1367b9ccdaa3d44e724a0"
};*/

//dev
var config ={
  apiKey: "AIzaSyA1oS3d-6lx85-ac3eHtw7EY1Q59Jq66Rk",
    authDomain: "dirix-bv.firebaseapp.com",
  databaseURL: "https://dirix-bv-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dirix-bv",
  storageBucket: "dirix-bv.appspot.com",
  messagingSenderId: "1013494205831",
  appId: "1:1013494205831:web:a09b55b0792e4413349722"
}
firebase.initializeApp(config);


ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));


