import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "../src/assets/chashierr.css";
import "../src/assets/desktop-css.css";
import "../src/assets/notebook-css.css";
import "../src/assets/tablet-css.css";
import "../src/assets/mobile-css.css";
import LoginScreen from "./views/LoginScreen";
import PrivateRoute from "./privateRoute";
import SignOnView from "./views/Authentication/SignOnView";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import RequestsFrontendView from "./views/Requests/RequestFrontendView";
import ThankYouView from "./views/Requests/ThankyouView";
import 'mapbox-gl/dist/mapbox-gl.css';


export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>
    
    <div>
   <Route exact path="/inloggen" component={LoginScreen}/>
   <Route exact path="/aanmelden" component={SignOnView}/>
   <Route exact path="/mijn-aanvraag" component={RequestsFrontendView}/>
   <Route exact path="/bedankt" component={ThankYouView}/>

  
   <Route exact path="/aanmelden-compleet" component={SignOnCompleteView}/>
      {routes.map((route, index) => {
        return (
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
    </Switch>
  </Router>
);
