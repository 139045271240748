import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import ClientsIndexComponent from "../../components/Clients";

import PageTitle from "../../components/common/PageTitle";
import IntranetComponent from "../../components/Intranet/IntranetComponent";



const IntranetView = () => (
  <Container fluid className="main-content-container px-4 backend">

    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Intranet" subtitle="Dirix B.V." className="text-sm-left" />
    </Row>


    <Row>
      <Col lg="12">
          <IntranetComponent />
       
      </Col>
    </Row>
  </Container>
);

export default IntranetView;
