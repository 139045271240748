import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';

class RequestsArchive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],
            searchItem:''
        };
    }



    componentDidMount() {
        this.getRequests();
    }

    getRequests(){
    
        let ref = Firebase.database().ref('/ASN/requests/');
        ref.once('value' , snapshot => {
        
        let state = snapshot.val();
        console.log(state)
        
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
         
          
                this.setState(prevState => ({
                    requests: [...prevState.requests, state],
                }))  
            })
        });

    }

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12}>
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
        {this.state.searchItem ?
        <Col sm={12} className="products-table archive-table">
        <h3>Zoeken in aanvragen</h3>
            <table>
                <tr>
                <th>ASN-Nummer</th>
                        <th>Naam</th>
                        <th>Product</th>
                        <th>Project adres</th>
                        <th>Beoordeling</th>
                        <th>Prijs</th>
                        <th>ASN</th>
                        <th>Status</th>
                        <th>Actie</th>
                </tr>

       {this.state.requests.filter((item) => this.state.searchItem ? item.companyName.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.companyAddress.toLowerCase().includes(this.state.searchItem.toLowerCase())|| item.companyPostalCity.toLowerCase().includes(this.state.searchItem.toLowerCase()) :item.name == item.name).filter((item)=> item.price.check == 'agreed').filter((item)=> item.rating.check == 'agreed').filter((item)=> item.requestStatus != 'new').map((item)=>(
            <tr >
               <td><b>{item.confirm != null ?item.confirm.asn: '-'}</b><br/></td>
                    <td><b>{item.companyName}</b><br/></td>
                    <td>{item.type}  <br/><i>{item.groundClass != 'geen grond' ? item.rating.groundClassIntern:null}</i></td>
                    <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }, {item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.price.check == 'in progress'?<i class="far fa-clock"></i>:null }{item.price.check == 'canceled'?<i class="fas fa-times"></i>:null }{item.price.check == 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>Actief</td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link></th>
            </tr>
       ))}
                   
       
                
                
            </table>
</Col>

        :
            <Col sm={12} className="products-table archive-table">
            <h3>Afgeronde aanvragen</h3>
                <table>
                    <tr>
                    <th>ASN-Nummer</th>
                        <th>Naam</th>
                        <th>Product</th>
                        <th>Project adres</th>
                        <th>Beoordeling</th>
                        <th>Prijs</th>
                        <th>ASN</th>
                        <th>Status</th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests?.filter((item)=> item.confirm != undefined ).filter((item)=> item.confirm.asn != undefined ).sort((a, b) =>    a.confirm.asn -b.confirm.asn   ).map((item)=>(
                <tr >
                    <td><b>{item.confirm != null ?item.confirm.asn: '-'}</b><br/></td>
                    <td><b>{item.companyName}</b><br/></td>
                    <td>{item.type}  <br/><i>{item.groundClass != 'geen grond' ? item.rating.groundClassIntern:null}</i></td>
                    <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }, {item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.price.check == 'in progress'?<i class="far fa-clock"></i>:null }{item.price.check == 'canceled'?<i class="fas fa-times"></i>:null }{item.price.check == 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>Actief</td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>}
                    
   
            </Row>
            </div>



        );
    }
};

export default RequestsArchive;