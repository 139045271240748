

export default function() {
  return [
    /*  {
      title: "Bestellingen",
      to: "/bestellingen",
      htmlBefore: 'shopping-cart',
      htmlAfter: ""
    }, */
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/dashboard-icon.svg?alt=media&token=a41bc23a-47d4-4d06-8e8c-cd5a0dac8572',
      htmlAfter: ""
    }, 
    {
      title: "Aanvragen ASN",
      to: "/aanvragen",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/bell-icon.svg?alt=media&token=a75572f6-ac70-4366-b9cf-338dd7ef64c0',
      htmlAfter: ""
    },
    {
      title: "Archief ASN",
      to: "/archief-asn",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/archive-svg.svg?alt=media&token=bf619c7d-29bc-4da5-b97e-551ef8a99d50',
      htmlAfter: ""
    },
    
    {
      title: "Analyserapporten",
      to: "/bodemrapporten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/file-svg.svg?alt=media&token=fc535d9d-6074-4f76-96bc-b3af15577845',
      htmlAfter: ""
    },
    {
      title: "Prijsafspraken",
      to: "/prijsafspraken",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/price.svg?alt=media&token=c1578b7a-3306-4d5e-a240-cf8e0b80f620',
      htmlAfter: ""
    },
/*
    {
      title: "Klanten",
      to: "/klanten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/users-solid.svg?alt=media&token=9cad03b1-a2fe-4327-874c-eb1db33976f2',
      htmlAfter: ""
    }, 
    {
      title: "Intranet",
      to: "/intranet",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/sitemap-solid.svg?alt=media&token=f4ec052a-7ce2-47e2-9d1e-752ddbc33964',
      htmlAfter: ""
    }, */

  
  ];
}
