import React, { Component,useContext, useEffect, useState } from 'react';
import {Route, Redirect} from "react-router-dom";
import {AuthContext} from "./Auth";
import Firebase from 'firebase';
import { useSpring } from 'framer-motion';

const PrivateRoute = ({component: RouteComponent, ...rest}) => {
    const {currentUser} = useContext(AuthContext);
    let [internUser,setInternUser] = useState(false)

    useEffect(()=>{
        Firebase.auth().onAuthStateChanged(firebaseUser => {

        if(firebaseUser !=null){
            if(firebaseUser.email.includes('dirix.nl') || firebaseUser.email.includes('pixelpros.nl')){
                setInternUser(true)
                console.log(internUser)
            }else{
                setInternUser(false)
            }
        }
            
          });

        
    })
    return(
        <Route
        {...rest}
        render={routeProps =>
        !!currentUser ? 
        internUser ? (
            <RouteComponent {...routeProps} />
        ):
        <Redirect to={"/mijn-aanvraag"} />
        
        : (
            <Redirect to={"/inloggen"} />
        )
        }
        />
    );
};

export default PrivateRoute