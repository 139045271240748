import React from "react";
import { Redirect } from "react-router-dom";
import RequestsArchive from "./components/Requests/RequestArchive";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";
import ClientsView from "./views/Clients/ClientsView";
import DashboardView from "./views/Dashboard/DashboardView";
import IntranetView from "./views/Intranet/IntranetView";
import LoginScreen from "./views/LoginScreen";
import Message from "./views/Messages/MessageView";
import Orders from "./views/Orders/OrdersView";

import Profile from "./views/Profile/ProfileView";
import AddGroundcardView from "./views/Requests/AddGroundcardView";
import GroundcardDetailView from "./views/Requests/GroundcardDetailView";
import GroundCardView from "./views/Requests/GroundCardView";
import PriceAgreesView from "./views/Requests/PriceAgreesView";
import RequestsDetailView from "./views/Requests/RequestDetailView";
import RequestsArchiveView from "./views/Requests/RequestsArchiveView";
import RequestsView from "./views/Requests/RequestsView";


// Route Views

import SignInScreen from "./views/signInView";



export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: DashboardView
  },  {
    path: "/aanvragen",
    exact: true,
    layout: DefaultLayout,
    component: RequestsView
  },
  {
    path: "/aanvragen/details",
    exact: true,
    layout: DefaultLayout,
    component: RequestsDetailView
  },
  {
    path: "/archief-asn",
    exact: true,
    layout: DefaultLayout,
    component: RequestsArchiveView
  },
  {
    path: "/bodemrapporten",
    exact: true,
    layout: DefaultLayout,
    component: GroundCardView
  },
  {
    path: "/bodemrapporten/details",
    exact: true,
    layout: DefaultLayout,
    component: GroundcardDetailView
  },
  {
    path: "/bodemrapporten/toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddGroundcardView
  },
  {
    path: "/prijsafspraken",
    exact: true,
    layout: DefaultLayout,
    component: PriceAgreesView
  },
  
  {
    path: "/klanten",
    exact: true,
    layout: DefaultLayout,
    component: ClientsView
  },
  {
    path: "/intranet",
    exact: true,
    layout: DefaultLayout,
    component: IntranetView
  },
  {
    path: "/bestellingen",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/profiel",
    exact: true,
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/berichten",
    exact: true,
    layout: DefaultLayout,
    component: Message
  },
  {
    
    path: "/sign-in",
    layout: ModalLayout,
    exact: true,
    component: SignInScreen
  },

];
