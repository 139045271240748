import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import DashboardIndex from "../../components/dashboard";
import RequestsIndex from "../../components/Requests";
import ThankYouComponent from "../../components/Requests/Thankyoucomponent";


const ThankYouView = () => (
  <Container >

          <ThankYouComponent />

  </Container>
);

export default ThankYouView;
