import React, { Component, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MapContainer  from './MapContainer';
import toast, { Toaster } from 'react-hot-toast';
import {Timeline, TimelineEvent} from 'react-event-timeline'

class GroundcardDetailsBackend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],desc:'',
            productDesc:'',
            price:'',
            priceName:'',
            priceMail:'',
            pricePhone:'',
            price:'',
            ratingName:'',
            ratingMail:'',
            ratingPhone:'',
            rating:'',
            asn:'',
            eural:'',
            nameProject:'',
            locationProject: '',
            postalProject:'',
            amountTon:'',
            groundClass:'',
            comingFrom: '',
            information: '',
            date:'',
            contactPerson:'',
            attachment:'',
            brl9335:'',
            ratingStatus:'',
            username:'',
            commentText:'',
            status:[],
            priceAttachment:'',
            tempUrlPrice:'',
            priceNameOfferte:'',
            nameAsn:'',
            groundClassIntern:'',
            ratingText:'',
            priceNavision:'',
            pre:''
        };
    
    }
    

    getStatus(){
        let ref = Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/status/');
        ref.on('value' , snapshot => {
            this.setState({status:[]})
           
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    status: [...prevState.status, state]
                })) 

            })

        })
        
    }
    


    componentDidMount() {

        this.getStatus();
        if(Firebase.auth().currentUser.uid === 'm9VWy6ccYFeMmAY5znl2ffAiuyn2'){
            this.setState({username: 'Joppe Meijers'})
        }else{
            this.setState({username: 'Tommie Amalo'})
        }

console.log(this.props.data.data.id)
this.setState({
    
    nameProject:this.props.data.data.companyNameOrigin,
    locationProject: this.props.data.data.companyAddressOrigin,
    postalProject:this.props.data.data.companyPostalOrigin ,
   
    contactPerson: this.props.data.data.companyContactOrigin,
    attachment:this.props.data.data.attachments,
   
})

console.log(this.props.data.data)
    }

    UpdateRating(){
        

        if(this.state.rating === 'verstuur'){
            console.log('mail voor aanvrager wordt verstuurd')

        }else{

            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/rating').update({
   
                check: this.state.rating ==='goedgekeurd' ? 'agreed': 'canceled',
                rating: this.state.rating,
                name:this.state.username,
                nameAsn:this.state.nameAsn,
                groundClassIntern:this.state.groundClassIntern,
                ratingText:this.state.ratingText,
                brl9335:this.state.brl9335,
                pre:this.state.pre
                
            
        }).then(()=>{
            this.addComment('description');
            /*let headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','http://localhost:3000');
            //headers.append('Origin','https://frontend-test-bbo.web.app/')
            headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
            fetch("https://us-central1-dirix-bv.cloudfunctions.net/CheckTwoSubmit", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
              })
              .then(res => {
                toast.success('Beoordeling is opgeslagen');
              })*/
        })
        }
   
    }

    UpdatePrice(){
        if(this.state.price === 'verstuur'){
            console.log('mail voor aanvrager wordt verstuurd')

        }else{

   
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
   
              check: this.state.price ==='goedgekeurd' ? 'agreed': 'canceled',
              rating: this.state.price,
       
              priceNavision: this.state.priceNavision,
              priceName:this.state.priceName,
              number: this.state.priceNameOfferte,
              attachment:this.state.attachment,

          
      }).then(()=>{
        this.addComment('euro');
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        /*fetch("https://us-central1-dirix-bv.cloudfunctions.net/MailToRos", {
            method: "POST",
            headers: headers,
              body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
          })
          .then(res => {
            toast.success('Prijs is geupdate');
          })*/
      })
    
    }
    }

    addComment(type){
        let today = new Date();
        var key = Firebase.database().ref().child('/ASN/requests/'+this.props.data.data.id+'/status/').push().key;

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/status/'+key).set({
   
            key: key,
            date: today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),
            text: this.state.commentText,
            user: this.state.username,
            type:type
        }).then(()=>{
            this.setState({
                commentText:''
            })
            toast.success('Opmerking opgeslagen')
        })

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
            requestStatus: 'running',
         })
    }

    UpdateASN(){
        if(this.state.price === 'verstuur'){
            console.log('mail voor aanvrager wordt verstuurd')

        }else{

   
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/confirm').update({
   
              asn: this.state.asn,
              eural: this.state.eural,
              

          
      }).then(()=>{
        this.addComment('pin');
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
       /* fetch("https://us-central1-dirix-bv.cloudfunctions.net/AccomplishEmailClient", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({email: this.props.data.data.companyMail, companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
          })
          .then(res => {
            toast.success('ASN-nummer is toegevoegd');
          })*/
      })
    
    }
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


    handleUploadCompanyLogo(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
 
    


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Dirix/attachments/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Dirix/attachments/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Document opgeslagen');
                  Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
                    attachment: url,
      
                

                    })

                    this.setState({
                        tempUrlPrice:url
                    })
               
                }).then(()=>{
                     

                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }


    render() {
    
        return (
            <div>
                          <Toaster/>
                <Row className="col-sm-12">
                    <Col sm="8" className="request-detail-8">
                    <h3>Afzender / ontdoener</h3>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyName}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam contactpersoon</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.contactPersonRequest}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Telefoon</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyPhone}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">E-mail</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyMail}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Adres</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyAddress}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Postcode + Woonplaats</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyPostalCity}</span>
                            <hr/> 
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Afvalstof</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.type}</span>
                            <hr/> 
                        </div>
                      
                        <span>
                        <h3>Locatie van herkomst</h3>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam / project: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyNameOrigin}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Straat + huisnr (geen postbus): </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyAddressOrigin}</span>
                            <hr/>
                           
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Postcode plaats: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyPostalOrigin}</span>
                            <hr/>
                            <Col sm={12} className="map-col">
     
                <MapContainer lng={this.props.data.data.long} lat={this.props.data.data.lat} />

                </Col>
                        </div></span>
                        
                        <span>
                        <h3>Gegevens grond</h3>
                        {this.props.data.data.amountTon !=''?
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Hoeveelheid (M3 / ton): </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.amountTon} {this.props.data.data.amountType}</span>
                            <hr/>
                        </div>:null}
                        {this.props.data.data.comingFrom !=''?
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Waar komt grond vrij: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.comingFrom == 'ondergrond' ?'Grond komt vrij uit ondergrond dieper dan 50cm minus maaiveld': 'Grond komt vrij uit bovengrond tot 50cm minus maaiveld'}</span>
                            <hr/>
                        </div>:null}
                        {this.props.data.data.information !=''?
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Voor info:  </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.information}</span>
                            <hr/>
                        </div>:null}
                        {this.props.data.data.date !=''?
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Datum van aanvoeren: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.date}</span>
                            <hr/>
                        </div>:null}
                        {this.props.data.data.companyContactOrigin !=''?
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Contact gehad met: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyContactOrigin}</span>
                            <hr/>
                        </div>:null}
                        
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Bijlage</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.attachments != '' ?<a target="_blank" href={this.props.data.data.attachments}><i class="fas fa-file-alt"> Bekijk</i></a>: 'Geen bijlage'}</span>
                            <hr/>
                        </div>
                        </span>
                    </Col>
                    <Col sm="4" className="request-detail-4">
          
                       
                       
                       {/*} <Row>
                            <Col sm="12" className="price-block">
                               
                            </Col>
                        </Row>*/}
                        
                        </Col>
                        
                    
                    
                </Row>
            </div>
        );
    }
};

export default GroundcardDetailsBackend;