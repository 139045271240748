import React from 'react';
import {Col, Row} from 'shards-react';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    render() {

        return (
            <div>
                <Row className="header-section desktop-menu">
                    <Col sm={2} className="logo-section">
                    <img alt='Het logo van Picoo' src={"https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/DIRIX_ONLINE_LOGO.svg?alt=media&token=cbfd907b-76ea-4ca1-a4bc-dc6113dbf332"}/>
                    </Col>
                    <Col sm={10} className="menu-section">
                        <a href="../home">Home</a>
                        
                    </Col>
                </Row>

                <Row className="mobile-menu">
                    <Col sm={12}>
                        <img alt='het logo van Picoo voor mobiel' src={"https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/DIRIX_ONLINE_LOGO.svg?alt=media&token=cbfd907b-76ea-4ca1-a4bc-dc6113dbf332"} className="mobile-logo-menu"/>
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        <nav className="navbar-mobile">

                            <ul>
                                <li><a className="menuLink" href="../home">Home</a></li>
                                <li><a className="menuLink" href="../over-ons">Over ons</a></li>
                                <li><a className="menuLink" href="../ontbijtbox">Verrasingsontbijtbox</a></li>
                                <li><a className="menuLink" href="../contact">Contact</a></li>
                                <li><a href="../kamer-overzicht" className="pre-order-item menuLink">Boek nu</a></li>
                            </ul>
                        </nav>
                    </Col>


        </Row>

            </div>
        );
    }
}

export default HeaderComponent;
