import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

import { Container } from "shards-react";

import { Link } from 'react-router-dom';



class ThankYouComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',


        };
    }


    componentDidMount(){
   localStorage.removeItem('polygon')

       
    }




    

    

    render() {
        return (
           

                    

                <Col sm={12} className="thank-you-col">

                    <Container>
                    
                    <Row >
                        <Col sm={6} className="thank-you-detail left-colum-thank-you">
                            <h2>BEDANKT VOOR JE AANVRAAG <span className="green-color-title">BIJ DIRIX.</span></h2>
                            <h4>Wij gaan voor je aan de slag.</h4>

                            


                        </Col>
                        <Col sm={6} className="thank-you-detail right-title">
                            <h2>HOE NU VERDER?</h2>
                            <div className="thank-you-block">
                                <h3 className="firstblock-thankyou">Je krijgt een aanvraagbevestiging per e-mail.</h3>
                                <p>Binnen enkele ogenblikken sturen wij jou een bevestiging Op: johndoe@gmail.com</p>
                            </div>
                            <div className="thank-you-block">
                                <h3 className="secondblock-thankyou">Ondertussen beoordelen wij jou aanvraag.</h3>
                                <p>We controlleren jouw aanvraag op de gestelde eisen. Op basis hiervan ontvang je een bericht van ons</p>
                            </div>
                            <div className="thank-you-block">
                                <h3 className="thirdblock-thankyou">Is je aanvraag goedgekeurd?</h3>
                                <p>Dan ontvang je van ons alle benodigde documenten om de grondstoffen af te voeren. </p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </Col>
               
               
                
                    
   

            
       



        );
    }
};

export default ThankYouComponent;