import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import Firebase from 'firebase';
import {AuthContext} from "../../Auth";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import LoginImage from '../../assets/images/login-image.png';
import Loader from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';

const Login = ({history}) =>{

    const handleLogin = useCallback(
         event =>{
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                 Firebase.auth().signInWithEmailAndPassword(email.value, password.value).catch(error => {   
                    switch(error.code) {
                      case 'auth/invalid-email':
                        toast.error('Controlleer het e-mail adres!');
                        console.log('Controlleer het e-mail adres!')
                    break;
                    case 'auth/user-not-found':
                        toast.error('E-mail adres is niet bekend!');
                        console.log('Controlleer het e-mail adres!')
                    break;
                    case 'auth/wrong-password':
                        toast.error('Controlleer het wachtwoord!');
                           console.log('Controlleer het wachtwoord!')
                          break;
                    default:
                        toast.error('Het e-mail adres is niet bij ons bekend, probeer het opnieuw');
                    break;
                            
                   }
                 });
                
             
               if(email.value.includes('dirix.nl') || email.value.includes('pixelpros.nl')){
                localStorage.setItem('Company','Dirix');
         
                history.push("/");
               }else if(email.value.includes('saldentransport.nl')){
                localStorage.setItem('Company','Salden Transport');
         
                history.push("/");
               }
               else if(email.value.includes('moermans')){
                localStorage.setItem('Company','Moermans');
         
                history.push("/");
               }
               else if(email.value.includes('boogers')){
                localStorage.setItem('Company','Boogers');
         
                history.push("/");
               }
               else if(email.value.includes('hudales')){
                localStorage.setItem('Company','Hudales');
         
                history.push("/");
               }
               else if(email.value.includes('groendenny')){
                localStorage.setItem('Company','Groendenny');
         
                history.push("/");
               }
               else if(email.value.includes('khgrondverzet')){
                localStorage.setItem('Company','khgrondverzet');
         
                history.push("/");
               }
               else if(email.value.includes('franssenfranken')){
                localStorage.setItem('Company','franssenfranken');
         
                history.push("/");
               }
               else if(email.value.includes('silencebreaking')){
                localStorage.setItem('Company','silencebreaking');
         
                history.push("/");
               }
               else if(email.value.includes('j-smh')){
                localStorage.setItem('Company','j-smh');
         
                history.push("/");
               }
               else if(email.value.includes('cremers')){
                localStorage.setItem('Company','Cremers');
         
                history.push("/");
               }
               else if(email.value.includes('loonbedrijf-fuchs')){
                localStorage.setItem('Company','Loonbedrijf Fuchs');
         
                history.push("/");
               }
               else if(email.value.includes('pfcontainers')){
                localStorage.setItem('Company','PF containers');
         
                history.push("/");
               }
               else if(email.value.includes('leers')){
                localStorage.setItem('Company','W.Leers Containers B.V.');
         
                history.push("/");
               }

                
            }catch (error){
                
            
            
            }
        },
        [history]
    );

    const { currentUser} = useContext(AuthContext);

    if (currentUser){
        return <Redirect to="/" />;
    }
    
    return (
      <Row >
   <Toaster/>
      <Col lg="6" className="login-col">
      <img src={"https://firebasestorage.googleapis.com/v0/b/dirix-bv.appspot.com/o/DIRIX_LOGO_BLAUW.png?alt=media&token=502a13b5-7ad6-4b5f-b8e1-2d0281aee46c"}  className="logo-login"/>
          <h3>Dirix Elsloo B.V.</h3>
          {/**<h4>Q-sort</h4> */}
          <p>Met behulp van dit platfoorm heb je jouw eigen plek om alle zaken rondom Dirix te zien.</p>
          <form onSubmit={handleLogin}>
          <Row >

          <input style={{padding: 10, marginTop: 10,}} name="email" type="email" placeholder="Gebruikersnaam" />
        
          </Row>
          <Row >

              <input style={{padding: 10, marginTop: 10,}} name="password" type="password" placeholder="Wachtwoord" />
  
          </Row>
          <button type="submit">Inloggen</button>
           {/** <div className="loading-dots">
            <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} timeout={10000}  />
    </div>*/}
      </form>
      </Col>

      <Col lg="6" className="login-image-bg"></Col>
    </Row>
    );
};


export default withRouter(Login);