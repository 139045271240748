import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";



class ClientsIndexComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }



    componentDidMount() {
  
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                
                    
   
            </Row>
            </div>



        );
    }
};

export default ClientsIndexComponent;