import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import DashboardIndex from "../../components/dashboard";
import RequestsIndex from "../../components/Requests";
import RequestDetailsBackend from "../../components/Requests/RequestsDetailsBackend";


const RequestsDetailView = (props) => (
  <Container fluid className="main-content-container px-4 backend">

    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Aanvragen afvalstroomnummers" subtitle="Dirix Elsloo B.V." className="text-sm-left" />
    </Row>


    <Row>
      <Col lg="12">
          <RequestDetailsBackend data={props.location.state}  />
       
      </Col>
    </Row>
  </Container>
);

export default RequestsDetailView;
