import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import HeaderComponent from "../../components/Header/headerComponent";
import RequestsFrontendIndex from "../../components/Requests/FrontedRequest";


const RequestsFrontendView = () => (
  <Container fluid  className="main-content-container px-4 ">
<HeaderComponent />

<Container>
    <Row>
      <Col lg="12">
          <RequestsFrontendIndex />
       
      </Col>
    </Row>
    </Container>
  </Container>
);

export default RequestsFrontendView;
