import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import Loader from "react-loader-spinner";
import { Redirect } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import toast, { Toaster } from 'react-hot-toast';
import  MapContainer  from './MapContainer';


class RequestsFrontendIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            differentPaymentAddress:false,
            companyName:'',
            companyAddress:'',
            companyPostalCity:'',
            amountTon:'',
            differentComingFrom:false,
            comingFromElse:'',
            comingFrom:'',
            informationDifferent:false,
            information:'',
            informationElse:'',
            thirdBlock:false,
            companyNameOrigin:'',
            companyPostalOrigin:'',
            companyAddressOrigin:'',
            companyContactOrigin:'',
            groundClass:'',
            date:'',
            companySubName:'',
            companySubAddress:'',
            companySubPaymentAddress:'',
            differentPFS:false,
            correct:false,
            loader:false,
            redirect:false,
            companyMail:'',
            companyPhone:'',
            imagesCompany:'',
            typeForm:false,
            typeSet:false,
                    imagesCompanyName:'',
                    imageAvailableCompanyLogo:false,
                    uploading: false,
                    changes:false,
                    showuploadlog:false,
                    multipleASN:false,
                    multipleASNS:[],
                    amountASN:1,
                    standard:true,
                    companyNameType:'Zakelijk',
                    companyContact:'',
                    typeAmount:'',
                    checkUndifined: false,
                    checkAw:false,
                    checkW0:false,
                    checkInd:false,
                    noContactPerson:false,
                    typePayment:'',
                    lat:'',
                    long:'',
                    typeName:'',
                    today:'',
                    companyCity:'',
                    companyHouseNumber:'',
                    companyCityOrigin:'',
                    companyAddressHousenumber:'',
                    resetPoly:'',
                    noHousenumber:false,
                    postalCorrect:false,
                    error:'',
                    companyNamePhonePerformer:'',
                    companyNameContactPerformer:'',
                    referentionNumber:''


        };
    }



    componentDidMount() {
        let date = new Date();
        let today = date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();
        this.setState({
            today: today
        })
    }

    DifferentPaymentAddress(){

        if(this.state.differentPaymentAddress ===  true){
            this.setState({
                differentPaymentAddress:false
            })
        }else if(this.state.differentPaymentAddress ===  false){
            this.setState({
                differentPaymentAddress:true
            })
        }
    }
    Correct(){
        if(this.state.correct ===  true){
            this.setState({
                correct:false
            })
        }else if(this.state.correct ===  false){
            this.setState({
                correct:true
            })
        }
    }
    DifferentPFS(){
        if(this.state.differentPFS===  true){
            this.setState({
                differentPFS:false
            })
        }else if(this.state.differentPFS ===  false){
            this.setState({
                differentPFS:true
            })
        }
    }

    ComingFrom(option){
        if(option === 'Andres'){
            this.setState({
                comingFrom: option,
                differentComingFrom:true
            })
        }else{
            this.setState({
                comingFrom: option,
                differentComingFrom:false
            })
        }

    }
    resetPoly(){
        console.log('clicked')
        this.setState({
            resetPoly:'hello'
        })
        localStorage.setItem('polygon','reset')
    }

    information(option){
        if(option === 'Andres'){
            this.setState({
                information: option,
                informationDifferent:true
            })
        }else{
            this.setState({
                information: option,
                informationDifferent:false
            })
        }

    }

    typeAsn(type){
        if(type == 'Grond' || type == 'Asbest' ||  type == 'Asfalt'){
            this.setState({
                typeForm:false,
                typeSet:true,
                typeName:type
            })
        }else{
            this.setState({
                typeForm:true,
                typeSet:true,
                typeName:type
            })
        } 
    }

    getAddressCoords(){
        if(this.state.postalCorrect){
        this.setState({
            thirdBlock:true
        })
        console.log('here')
        let headers = new Headers();
       
        fetch("https://maps.googleapis.com/maps/api/geocode/json?address="+this.state.companyAddressOrigin+" "+this.state.companyAddressHousenumber+" "+this.state.companyPostalOrigin+" "+this.state.companyCityOrigin+"&key=AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0", {
                method: "POST",
            
                
              })
              .then(res => {
              
                return res.json();
              })
              .then(data => {
                console.log(data.results[0].geometry.location.lat)
                this.setState({
                    lat:data.results[0].geometry.location.lat,
                    long:data.results[0].geometry.location.lng
                })
                
                console.log(data.results[0].geometry.location.lat)
                console.log(data.results[0].geometry.location.lng)
        
                
              })}else{
                  this.setState({
                      error:'Controlleer de postcode'
                  })
              }
    }
    sendRequest(){
        let date = new Date();
        let today = date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();
        this.setState({
            loader:true,
        })

        if(this.state.multipleASNS.length>0){

            this.state.multipleASNS.map((item)=>{

       
        var key = Firebase.database().ref().child('/ASN/requests/').push().key;
          Firebase.database().ref('/ASN/requests/'+key).set({
            id: key,
            requestDate:today,
            companyNameType: this.state.companyNameType,
            companyName: this.state.companyName,
            companyAddress: this.state.companyAddress+ ' ' +this.state.companyHouseNumber,
            companyPostalCity:this.state.companyPostalCity+ ' ' +this.state.companyCity,
            differentPaymentAddress: this.state.differentPaymentAddress,
            companyNameContactPerformer: this.state.companyNameContactPerformer,
            companyNamePhonePerformer:this.state.companyNamePhonePerformer,
            referentionNumber:this.state.referentionNumber,
            companySubName: this.state.differentPaymentAddress ? this.state.companySubName: 'none',
            companySubAddress: this.state.differentPaymentAddress ? this.state.companySubAddress: 'none',
            companySubPaymentAddress: this.state.differentPaymentAddress ? this.state.companySubPaymentAddress: 'none',
            amountTon: item.amountTon !=''?item.amountTon:'',
            amountType: item.typeAmount !=''?item.typeAmount:'',
            groundClass: item.groundClass !=''?item.groundClass:'',
            comingFrom: item.comingFrom !=''?item.comingFrom:'',
            information: item.information !=''?item.information:'',
            attachments: item.attachments !=''?item.attachments:'',
            date:item.date,
            type: item.typeName,
            lat:this.state.lat,
            long:this.state.long,
            differentPFS: this.state.differentPFS,
            correct: this.state.correct,
            companyNameOrigin:this.state.companyNameOrigin,
            companyAddressOrigin:this.state.companyAddressOrigin +' '+this.state.companyAddressHousenumber,
            companyPostalOrigin:this.state.companyPostalOrigin +' '+this.state.companyCityOrigin,
            companyContactOrigin:this.state.noContactPerson ? 'Geen eerder contact' : this.state.companyContactOrigin,
            companyMail:this.state.companyMail,
            companyPhone:this.state.companyPhone,
            attachments: this.state.imagesCompany,
            contactPersonRequest:this.state.companyNameType == 'Particulier'? this.state.companyName :this.state.companyContact,
            typePayment: this.state.typePayment,
            markers:localStorage.getItem('markers') != null?localStorage.getItem('markers'):'geen markers',
            requestStatus: 'new',
            price:{
                check: 'in progress'
            },
            rating:{
                check:'in progress'
            }
        }).then(()=>{
            let headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','http://localhost:3000');
            //headers.append('Origin','https://frontend-test-bbo.web.app/')
            headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
           fetch("https://us-central1-dirix-bv.cloudfunctions.net/SubmitForm", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({companyName:  this.state.companyName ,adres: this.state.companyAddress,postal: this.state.companyPostalCity})
              })
              .then(res => {
             
              })
              setTimeout(() => { 
              fetch("https://us-central1-dirix-bv.cloudfunctions.net/ConfirmEmailClient", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({companyName:  this.state.companyName,adres: this.state.companyAddress,postal: this.state.companyPostalCity,email:this.state.companyMail})
              })
              .then(res => {
             
              })
            },1000)
        }).then(()=>{

          
           
        })     })
    }else{


       
            var key = Firebase.database().ref().child('/ASN/requests/').push().key;
              Firebase.database().ref('/ASN/requests/'+key).set({
                id: key,
                requestDate:today,
                companyNameType: this.state.companyNameType,
                companyName: this.state.companyName,
                companyAddress: this.state.companyAddress+ ' ' +this.state.companyHouseNumber,
                companyPostalCity:this.state.companyPostalCity+ ' ' +this.state.companyCity,
                differentPaymentAddress: this.state.differentPaymentAddress,
                companySubName: this.state.differentPaymentAddress ? this.state.companySubName: 'none',
                companySubAddress: this.state.differentPaymentAddress ? this.state.companySubAddress: 'none',
                companySubPaymentAddress: this.state.differentPaymentAddress ? this.state.companySubPaymentAddress: 'none',
                companyNameContactPerformer: this.state.companyNameContactPerformer,
                companyNamePhonePerformer:this.state.companyNamePhonePerformer,
                referentionNumber:this.state.referentionNumber,
                amountTon: this.state.amountTon  != ''? this.state.amountTon:'',
                amountType: this.state.typeAmount   != ''? this.state.typeAmount:'',
                groundClass: this.state.groundClass != ''? this.state.groundClass:'geen grond',
                comingFrom: this.state.comingFrom != ''? this.state.comingFrom:'',
                information: this.state.information  != ''? this.state.information:'',
                attachments: this.state.attachments,
                date:this.state.date,
                type: this.state.typeName   != ''? this.state.typeName:'',
                lat:this.state.lat,
                long:this.state.long,
                differentPFS: this.state.differentPFS  != ''? this.state.differentPFS:'',
                correct: this.state.correct,
                companyNameOrigin:this.state.companyNameOrigin,
                companyAddressOrigin:this.state.companyAddressOrigin +' '+this.state.companyAddressHousenumber,
                companyPostalOrigin:this.state.companyPostalOrigin +' '+this.state.companyCityOrigin,
                companyContactOrigin: this.state.noContactPerson ? 'Geen eerder contact' : this.state.companyContactOrigin,
                companyMail:this.state.companyMail,
                companyPhone:this.state.companyPhone,
                attachments: this.state.imagesCompany,
                contactPersonRequest:     this.state.companyNameType == 'Particulier'? this.state.companyName :this.state.companyContact,
                requestStatus: 'new',
                markers:localStorage.getItem('markers') != null?localStorage.getItem('markers'):'geen markers',
                price:{
                    check: 'in progress'
                },
                rating:{
                    check:'in progress'
                }
            }).then(()=>{
                let headers = new Headers();
    
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Origin','http://localhost:3000');
                //headers.append('Origin','https://frontend-test-bbo.web.app/')
                headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
                fetch("https://us-central1-dirix-bv.cloudfunctions.net/SubmitForm", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({companyName:  this.state.companyName ,adres: this.state.companyAddress,postal: this.state.companyPostalCity})
                  })
                  .then(res => {
                 
                  })
                  setTimeout(() => { 
                  fetch("https://us-central1-dirix-bv.cloudfunctions.net/ConfirmEmailClient", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({companyName:  this.state.companyName,adres: this.state.companyAddress,postal: this.state.companyPostalCity,email:this.state.companyMail})
                  })
                  .then(res => {
                 
                  })
                },1000)
            }).then(()=>{
    
              
               
            })     
    }

    setTimeout(() => { 
        this.setState({
            loader:false,
            redirect:true
        })

},3000)
        
    }
    

    
     handleChange = (event) => {
         console.log(event)
         this.setState({
            groundClass: event
         })
         if(event == 'Niet bekend'){
             this.setState({
                checkUndifined:true,
                checkAw:false,
                checkW0:false,
                checkInd:false
             })
         }else if(event == 'AW'){
            this.setState({
                checkUndifined:false,
                checkAw:true,
                checkW0:false,
                checkInd:false
             })
         }else if(event == 'WO'){
            this.setState({
                checkUndifined:false,
                checkAw:false,
                checkW0:true,
                checkInd:false
             })
         }else if(event == 'IND'){
            this.setState({
                checkUndifined:false,
                checkAw:false,
                checkW0:false,
                checkInd:true
             })
         }
      };

      handleChangeCompanyLogo(e){
 
        this.setState({
         imageCompanyLogo: e.target.files[0],
          errorMessageLogo:''
        })
    
    }

    presetIntern(value){
        if(value =='Dirix containers'){
            this.setState({
                companyName:'Dirix containers',
                companyAddress:'Business Park Stein',
                companyHouseNumber:'230',
                companyPostalCity:'6181 MB',
                companyCity:'Elsloo'
            })
        }else if(value == 'Dirix sloopwerken'){
            this.setState({
                companyName:'Dirix sloopwerken',
                companyAddress:'Business Park Stein',
                companyHouseNumber:'230',
                companyPostalCity:'6181 MB',
                companyCity:'Elsloo'
            })
        }else if(value == 'Dirix infra'){
            this.setState({
                companyName:'Dirix infra',
                companyAddress:'Business Park Stein',
                companyHouseNumber:'230',
                companyPostalCity:'6181 MB',
                companyCity:'Elsloo'
            })
        }
        else if(value == 'Salden Transport'){
            this.setState({
                companyName:'Salden Transport',
                companyAddress:'Bergerweg',
                companyHouseNumber:'11',
                companyPostalCity:'6142 AA',
                companyCity:'Einighausen',
                companyContact:'Yvo Salden',
                companyPhone:'0654208099',
                companyMail:'info@saldentransport.nl'
            })
        }
        else if(value == 'Moermans'){
            this.setState({
                companyName:'W.G. Moermans B.V.',
                companyAddress:'Kampstraat',
                companyHouseNumber:'16',
                companyPostalCity:'6163 HG',
                companyCity:'Geleen',
                companyContact:'Kristel Moermans',
                companyPhone:'046-4747424',
                companyMail:'wgmoermansbv@gmail.com'
            })
        }
        else if(value == 'Boogers'){
            this.setState({
                companyName:'Boogers Recycling',
                companyAddress:'Heirstraat',
                companyHouseNumber:'26',
                companyPostalCity:'6129 PK',
                companyCity:'Urmond',
                companyContact:'',
                companyPhone:'046-4333056',
                companyMail:'info@boogersrecycling.nl'
            })
        }
        else if(value == 'Cremers'){
            this.setState({
                companyName:'Loonbedrijf S.Cremers',
                companyAddress:'Eindstraat',
                companyHouseNumber:'13',
                companyPostalCity:'6454 BA',
                companyCity:'Jabeek',
                companyContact:'Stefan',
                companyPhone:'046-4333056',
                companyMail:'stefan@loonbedrijfcremers.com'
            })
        }
        else if(value == 'Hudales'){
            this.setState({
                companyName:'Hudales',
                companyAddress:'Kampstraat',
                companyHouseNumber:'22',
                companyPostalCity:'6163 HG',
                companyCity:'Geleen',
                companyContact:'',
                companyPhone:'046 - 47 47 092',
                companyMail:'administratie@hudales.eu'
            })
        }
        else if(value == 'Groendenny'){
            this.setState({
                companyName:'Multigroen Denny',
                companyAddress:'Graetheide',
                companyHouseNumber:'1',
                companyPostalCity:'6135 KP',
                companyCity:'Sittard',
                companyContact:'',
                companyPhone:'06 - 13 11 73 39',
                companyMail:'info@multigroendenny.nl'
            })
        }
        else if(value == 'khgrondverzet'){
            this.setState({
                companyName:'KH Grondverzet',
                companyAddress:'Havenstraat',
                companyHouseNumber:'6',
                companyPostalCity:'6171 EE',
                companyCity:'Stein',
                companyContact:'',
                companyPhone:'06 - 29 07 47 55',
                companyMail:'khgrondverzet@gmail.com'
            })
        }
        else if(value == 'franssenfranken'){
            this.setState({
                companyName:'Franssen - Franken',
                companyAddress:'Marconistraat',
                companyHouseNumber:'40',
                companyPostalCity:'6372 PN',
                companyCity:'Landgraaf',
                companyContact:'',
                companyPhone:'045 - 53 22 400',
                companyMail:'transport@FranssenFranken.com'
            })
        }
        else if(value == 'silencebreaking'){
            this.setState({
                companyName:'Silence Breaking',
                companyAddress:'Markt',
                companyHouseNumber:'4A',
                companyPostalCity:'6191 JJ',
                companyCity:'Beek',
                companyContact:'',
                companyPhone:'06 - 10 67 05 27',
                companyMail:'info@silencebreaking.com'
            })
        }
        else if(value == 'Loonbedrijf Fuchs'){
            this.setState({
                companyName:'Loonbedrijf Fuchs',
                companyAddress:'Obbichterweg',
                companyHouseNumber:'2',
                companyPostalCity:'6121 RT',
                companyCity:'Born',
                companyContact:'',
                companyPhone:'046-485 52 09',
                companyMail:'info@loonbedrijf-fuchs.com'
            })
        }
        else if(value == 'j-smh'){
            this.setState({
                companyName:'SMH',
                companyAddress:'Slekkerstraat',
                companyHouseNumber:'17A',
                companyPostalCity:'6102 VH',
                companyCity:'Echt',
                companyContact:'',
                companyPhone:'06 - 42 77 72 48',
                companyMail:'j-smh@hotmail.com'
            })
        }
        else if(value == 'W.Leers Containers B.V.'){
            this.setState({
                companyName:'W.Leers Containers B.V.',
                companyAddress:'De Kling',
                companyHouseNumber:'51',
                companyPostalCity:'6333 ER',
                companyCity:'Schimmert',
                companyContact:'',
                companyPhone:'06-20604863',
                companyMail:'containers@leers.nl'
            })
        }
        else if(value == 'PF containers'){
            this.setState({
                companyName:'PF containers',
                companyAddress:'Dorpstraat',
                companyHouseNumber:'15',
                companyPostalCity:'6261 NH',
                companyCity:'Mheer',
                companyContact:'',
                companyPhone:'06-44809140',
                companyMail:'info@pfcontainers.nl'
            })
        }
        else if(value == 'Cordewener Groen Hoveniersbedrijf'){
            this.setState({
                companyName:'Cordewener Groen Hoveniersbedrijf',
                companyAddress:'Pasweg',
                companyHouseNumber:'1A',
                companyPostalCity:'6241 CT',
                companyCity:'Bunde',
                companyContact:'',
                companyPhone:'06-21267955',
                companyMail:'info@cordewener-groen.nl'
            })
        }
        else if(value == 'Tuinadviesbureau en Hoveniersbedrijf Klinkers'){
            this.setState({
                companyName:'Tuinadviesbureau en Hoveniersbedrijf Klinkers',
                companyAddress:'Hegstraat',
                companyHouseNumber:'9',
                companyPostalCity:'6161 BC',
                companyCity:'Geleen',
                companyContact:'',
                companyPhone:'06-53693430',
                companyMail:'info@hovenierklinkers.nl'
            })
        }
        else if(value == 'Vranken Verhuur containers'){
            this.setState({
                companyName:'Vranken Verhuur containers',
                companyAddress:'Bernhardstraat',
                companyHouseNumber:'15',
                companyPostalCity:'6191 XJ',
                companyCity:'Beek',
                companyContact:'',
                companyPhone:'06-24823492',
                companyMail:'info@vranckenverhuur.nl'
            })
        }
    
     
     
    }

    addASN(){
        console.log(this.state.typeForm)
        this.setState({
            multipleASN:true,
            amountASN: this.state.amountASN+1
        })
    
        let newAsn = {key:this.state.multipleASNS.length, amountTon:this.state.amountTon,typeAmount:this.state.typeAmount,groundClass:this.state.groundClass,comingFrom:this.state.comingFrom,information:this.state.information,date:this.state.date, attachments: this.state.imagesCompany,open:false,typeForm:this.state.typeForm,typeName: this.state.typeName};
        this.setState(prevState =>({
            multipleASNS: [...prevState.multipleASNS,newAsn]
        }))

        this.setState({
            amountTon:'',
            groundClass:'',
            comingFrom:'',
            information:'',
            date:'', 
            imagesCompany: '',
            typeForm:false,
            typeName:''
        })

        console.log(this.state.multipleASNS)
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


    handleUploadCompanyLogo(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
      const {imageCompanyLogo} = this.state;
      this.setState({
        uploaded:true,
        loadingComapny:true,
        handleUpload:true
      })
      const query = new URLSearchParams(this.props.id);


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Dirix/attachments/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Dirix/attachments/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Document opgeslagen');
                    
                  this.setState(prevState =>({
                    imagesCompany:url,
                    imagesCompanyName:uniqueId+logoFile.name,
                    imageAvailableCompanyLogo:true,
                    uploading: true,
                    changes:true,
                    showuploadlog:false,
                   
                  }))
                }).then(()=>{
                      this.setState({ overlay:false})
                      //  this.updateData();
                     // setTimeout(() => {  this.getUrl(this.state.companyName,this.state.imagesCompanyName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')) }, 6000);

                }).then(()=>{
                    console.log(this.state.companyName)
                    this.setState({
                        loadingComapny:true
                    })

               
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }
    
    changeAccordion( number){


        for(let i = 0; i < this.state.multipleASNS.length; i++){
   
            if(i == number){
                console.log(number)
                let multipleASNS = [...this.state.multipleASNS];
                let question = {...this.state.multipleASNS[i]};
                if(question.open === true){
                    question.open =false
                } else if(question.open === false){
                    question.open =true
                }

       
                
                multipleASNS[i] = question

                this.setState({multipleASNS})


                //this.faqQuestions = this.faqQuestions;

        
            }
        }
    }

    checkPostal(event){
        this.setState({ companyPostalOrigin: event, errorMessage:''})
        var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
     
        if(rege.test(event)){
            console.log('klopt')
            this.setState({
                postalCorrect:true
            })
        }else{
            console.log('klopt niet')
            this.setState({
                postalCorrect:false
            })
        }
    }

    

    render() {
       if( this.state.redirect){
        return <Redirect to={'/bedankt'} /> 
       }
       
        return (
     
            <Row className="col-sm-12">
                       <Toaster />
           
                <Col sm="12">
                <h2>Mijn aanvraag</h2>
                <Row>
                <Col sm="3" className={this.state.standard ? "request-form-choose ": "request-form-choose not-active"}onClick={()=>this.setState({standard:true})}>
                        <h2>Aanvragen ASN</h2>
                    </Col>
                    <Col sm="3" className={this.state.standard ? "request-form-choose not-active": "request-form-choose"}  onClick={()=>this.setState({standard:false})}>
                         <h2>ASN meerdere locaties</h2>
                    </Col>
                </Row>
{this.state.standard?
                <Row>
               
                    <Col sm="6" className="request-form">
                        <h3>Afzender / ontdoener </h3>
                            <span className="input-field-request">
                                {localStorage.getItem('Company') === 'Dirix'?<>
                        <label>Intern gebruik: </label>
                            <br/>
                            <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                <option >Selecteer een bedrijf</option>
                                <option value="Dirix containers">Dirix containers</option>
                                <option value="Dirix sloopwerken"> Dirix sloopwerken</option>
                                <option value="Dirix infra">Dirix infra</option>
                                <option value="Cordewener Groen Hoveniersbedrijf">Cordewener Groen Hoveniersbedrijf</option>
                                <option value="Tuinadviesbureau en Hoveniersbedrijf Klinkers">Tuinadviesbureau en Hoveniersbedrijf Klinkers</option>
                                <option value="Vranken Verhuur containers">Vranken Verhuur containers</option>
                            </select>
                            <br/>
                            </>
                            :null}
                                                            {localStorage.getItem('Company') === 'Boogers'?<>
                        <label>Intern gebruik: </label>
                            <br/>
                            <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                <option >Selecteer een bedrijf</option>
                                <option value="Boogers">Boogers Recycling</option>
                                
                            </select>
                            <br/>
                            </>
                            :null}
                                    {localStorage.getItem('Company') === 'Salden Transport'?<>
                                    
                        <label>Intern gebruik: </label>
                            <br/>
                            <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                <option >Selecteer een bedrijf</option>
                                <option value="Salden Transport">Salden Transport</option>
                               
                            </select>
                            <br/>
                            </>
                            :null}
                                           {localStorage.getItem('Company') === 'Cremers'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Loonbedrijf S. Cremers">Loonbedrijf S. Cremers</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                                  {localStorage.getItem('Company') === 'Moermans'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Moermans">W.G. Moermans B.V.</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                                {localStorage.getItem('Company') === 'Hudales'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Hudales">Hudales</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                                                                        {localStorage.getItem('Company') === 'Groendenny'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Multigroen Denny">Multigroen Denny</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}

{localStorage.getItem('Company') === 'khgrondverzet'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="KH Grondverzet">KH Grondverzet</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                                        {localStorage.getItem('Company') === 'franssenfranken'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Franssen - Franken">Franssen - Franken</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                    {localStorage.getItem('Company') === 'silencebreaking'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="Silence Breaking">Silence Breaking</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}

{localStorage.getItem('Company') === 'j-smh'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="SMH">SMH</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
{localStorage.getItem('Company') === 'PF containers'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="PF containers">PF containers</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
{localStorage.getItem('Company') === 'W.Leers Containers B.V.'?<>
                                    
                                    <label>Intern gebruik: </label>
                                        <br/>
                                        <select onChange={(event)=> this.presetIntern(event.target.value)}>
                                            <option >Selecteer een bedrijf</option>
                                            <option value="W.Leers Containers B.V.">W.Leers Containers B.V.</option>
                                           
                                        </select>
                                        <br/>
                                        </>
                                        :null}
                                {this.state.companyNameType == 'Zakelijk'?   
                            <span className="input-field-request">
                                <label>Naam bedrijf: </label>
                                <br/>
                                <input required type="text" name="company-name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})}/>
                                </span> :null}
                        </span>
                  
                       
                        <span className="input-field-request">
                            <label>Straatnaam: </label>
                            <br/>
                            <input required type="text" name="company-adres" value={this.state.companyAddress} onChange={event => this.setState({ companyAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Huisnummer: </label>
                            <br/>
                            <input required type="text" name="company-adres" value={this.state.companyHouseNumber} onChange={event => this.setState({ companyHouseNumber: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Postcode: </label>
                            <br/>
                            <input required type="text" name="company-postal-city" value={this.state.companyPostalCity} onChange={event => this.setState({ companyPostalCity: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Plaats: </label>
                            <br/>
                            <input required type="text" name="company-postal-city" value={this.state.companyCity} onChange={event => this.setState({ companyCity: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>E-mail </label>
                            <br/>
                            <input required type="text" name="company-mail" value={this.state.companyMail} onChange={event => this.setState({ companyMail: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Telefoon</label>
                            <br/>
                            <input required type="text" name="company-phone" value={this.state.companyPhone} onChange={event => this.setState({ companyPhone: event.target.value, errorMessage:''})}/>
                        </span>
                        {this.state.companyNameType == 'Particulier'?  null:
                        <span className="input-field-request">
                            <label>Naam contactpersoon</label>
                            <br/>
                            <input required type="text" name="company-phone" value={this.state.companyContact} onChange={event => this.setState({ companyContact: event.target.value, errorMessage:''})}/>

                        </span>}
                        {this.state.companyNameType == 'Zakelijk'?   
                            <span className="input-field-request">
                                <label>Referentie nummer: </label>
                                <br/>
                                <input required type="text" name="company-name" value={this.state.referentionNumber} onChange={event => this.setState({ referentionNumber: event.target.value, errorMessage:''})}/>
                                </span> :null}
                        <span className="input-field-request">
                            <label style={{width:'50%'}}><input type="checkbox"  value={this.state.differentPaymentAddress} onClick={() => this.DifferentPaymentAddress()}/> Afwijkend factuuradres </label>
                            {this.state.differentPaymentAddress ? null:  <button className="next-button-form" onClick={()=>this.setState({secondBlock:true})}>Volgende</button>}
                            
                        </span>

{this.state.differentPaymentAddress ?<span>
                        <h3>Factuuradres</h3>
                        <span className="input-field-request">
                            <label>Naam bedrijf: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubName} onChange={event => this.setState({ companySubName: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Factuuradres + huisnummer: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubAddress} onChange={event => this.setState({ companySubAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Factuurpostcode + vestigingplaats: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubPaymentAddress} onChange={event => this.setState({ companySubPaymentAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            
                            <button className="next-button-form" onClick={()=>this.setState({secondBlock:true})}>Volgende</button>
                        </span>
                        <br/><br/>   <br/><br/>
                        </span>:null}
                            <hr/>
                        <span className={this.state.secondBlock ?"is-active":"not-active"}>
                        <h3>Locatie van herkomst</h3>
                        <span className="input-field-request">
                            <label>Naam / project: </label>
                            <br/>
                            <input required type="text" name="company-name-origin" value={this.state.companyNameOrigin} onChange={event => this.setState({ companyNameOrigin: event.target.value, errorMessage:''})}/>
                            
                        </span>
                        <span className="input-field-request">
                            <label>Naam contactpersoon uitvoerder: </label>
                            <br/>
                            <input required type="text" name="company-name-origin" value={this.state.companyNameContactPerformer} onChange={event => this.setState({ companyNameContactPerformer: event.target.value, errorMessage:''})}/>
                            
                        </span>
                        <span className="input-field-request">
                            <label>Telefoon contactpersoon uitvoerder: </label>
                            <br/>
                            <input required type="text" name="company-name-origin" value={this.state.companyNamePhonePerformer} onChange={event => this.setState({ companyNamePhonePerformer: event.target.value, errorMessage:''})}/>
                            
                        </span>
                        <span className="input-field-request">
                            <label>Straat: </label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.companyAddressOrigin} onChange={event => this.setState({ companyAddressOrigin: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Huisnummer: </label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.companyAddressHousenumber} onChange={event => this.setState({ companyAddressHousenumber: event.target.value, errorMessage:''})}/>
                            <label style={{width:'100%',marginTop:'-5%!important'}}><input type="checkbox"  checked={this.state.noHousenumber} onChange={()=>this.setState({noHousenumber: this.state.noHousenumber ? false: true,companyAddressHousenumber:this.state.noHousenumber?'geen huisnummer bekend':this.state.companyAddressHousenumber })}/>Er is nog geen huisnummer</label>
                        </span>
                        <span className="input-field-request">
                            <label>Postcode: </label>
                            <br/>
                            <input  required type="text" name="amount-ton" pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$" value={this.state.companyPostalOrigin} onChange={event => this.checkPostal(event.target.value)}/>
                        </span>
                        <span className="input-field-request">
                            <label>Plaats: </label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.companyCityOrigin} onChange={event => this.setState({ companyCityOrigin: event.target.value, errorMessage:''})}/>
                        </span>
                     
                        <span className="input-field-request">
                             <button disabled={this.state.postalCorrect?false:true} className="next-button-form" onClick={()=>this.getAddressCoords()}>Volgende</button>
                        </span>
                        <br/><br/><br/>
                        <hr/>
                        </span>

                        

                         <span className={this.state.thirdBlock ?"is-active":"not-active"}>
                         <h3 >Gegevens van afvalstof</h3>
                      {this.state.multipleASN?
                         this.state.multipleASNS.map((question, i) =>(
                           <Col sm={12} className="faq-col" onClick={()=>this.changeAccordion(i)}>
                           <h5>{question.typeName}</h5>{question.open ? <i class="fas fa-chevron-up"></i>: <i class="fas fa-chevron-down"></i>}
                          {question.open ? <Row className="faq-row-para">
                               <Col sm={12}>
                                   <p>
                                       <ul>
                                       <li>Benaming:  {question.typeName}</li>
                                                        { question.amountTon?<li>Aantal ton:  {question.amountTon}</li>:null}
                                                        { question.groundClass? <li>Klasse:  {question.groundClass}</li>:null}
                                                        { question.comingFrom? <li>Komt vandaan van:  {question.comingFrom}</li>:null}
                                                        { question.information?<li>Informatie  {question.information}</li>:null}
                                                        { question.date? <li>Afvoer datum  {question.date}</li>:null}
                                                        { question.attachments? <li>Bijlage  {question.attachments}</li>:null}
                                        </ul>
                                  
                                    </p>
                               </Col>
                           </Row>:null}
                       </Col>

                )):null}


                                     
                         <span> 
                            <span className="input-field-request">
                        <label>Type afvalstof: </label>
                            <br/>
                            <select value={this.state.typeName} onChange={(event)=> this.typeAsn(event.target.value)}>
                                <option value="Selecteer een type" >Selecteer een type</option>
                                <option value="Puin">Puin</option>
                                <option value="Hout A,B">Hout A/B</option>
                                <option value="C-hout">C-hout</option>
                                <option value="Metalen">Metalen</option>
                                <option value="Asfalt (Teerhoudend)">Asfalt (Teerhoudend)</option>
                                <option value="Asfalt (Teervrij)">Asfalt (Teervrij)</option>
                                <option value="Dakbedekking">Dakbedekking</option>
                                <option value="Bouw & Sloopafval">Bouw & Sloopafval</option>
                                <option value="Grond">Grond</option>
                                <option value="Funderingsmateriaal">Funderingsmateriaal</option>
                                <option value="Betonpuin">Betonpuin</option>
                                <option value="Ballast">Ballast</option>
                                <option value="Asbest houdend bouwmateriaal">Asbest houdend bouwmateriaal</option>
                                <option value="Asbest houdend metaal">Asbest houdend metaal</option>
                                <option value="Groenafval">Groenafval</option>
                                <option value="Gasbeton">Gasbeton</option>
                                <option value="Gips">Gips</option>
                            </select>
                            <br/>
                            {this.state.informationDifferent?  <input  type="text" className="new-input-field" name="amount-ton" value={this.state.informationElse} onChange={event => this.setState({ informationElse: event.target.value, errorMessage:''})}/> :null}
                        </span>
                    {this.state.typeForm  && this.state.typeSet ? 
                    <span>
                        <span className="input-field-request">
                            <label>Hoeveelheid: </label>
                         <br/>
                            <input required type="text" name="amount-ton" value={this.state.amountTon} onChange={event => this.setState({ amountTon: event.target.value, errorMessage:''})}/>
                            <br/> <select onChange={(event)=> this.setState({typeAmount:event.target.value})}>
                                <option >Selecteer een eenheid</option>
                                <option value="m3">m3</option>
                                <option value="Ton">Ton</option>
                            </select>
                          
                        </span>
                        <span className="input-field-request">
                            <h3>Voeg extra afvalstof toe</h3>
                         <br/>
                         <button className="add-asn" onClick={()=>this.addASN()}>Toevoegen</button>
                        </span><br/><br/>
                    
                    </span>

                    :
                    this.state.typeName == 'Grond'?
                    
                    <span>
                        <span className="input-field-request">
                            <label>Hoeveelheid: </label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.amountTon} onChange={event => this.setState({ amountTon: event.target.value, errorMessage:''})}/>
                            <br/> <select onChange={(event)=> this.setState({typeAmount:event.target.value})}>
                                <option >Selecteer een eenheid</option>
                                <option value="m3">m3</option>
                                <option value="Ton">Ton</option>
                            </select>
                        </span>
                        <span className="input-field-request">
                        <label>Voor info: </label>
                            <br/>
                            <select value={this.state.information} onChange={(event)=> this.information(event.target.value)}>
                                <option value="Selecteer een optie">Selecteer een optie</option>
                                <option value="Niet bekend">Niet bekend</option>
                                <option value="VBO">VBO</option>
                                <option value="AP04">AP04</option>
                                <option value="BKk">Bkk</option>
                                <option value="Andres">Anders</option>
                            </select>
                            <br/>
                            {this.state.informationDifferent?  <input  type="text" className="new-input-field" name="amount-ton" value={this.state.informationElse} onChange={event => this.setState({ informationElse: event.target.value, errorMessage:''})}/> :null}
                        </span>
                        {this.state.long?
                        <span className="input-field-request map-frontend">
                        <label>Locatie: </label>
                  
                            <MapContainer clickedonbutton={this.state.resetPoly} lng={this.state.long} lat={this.state.lat}/>
                     
                        </span>   :null}
                        <div className="input-field-request checkbox margin-top-gegevens">
                            <i>Indien wenselijk, klik op de map om het gebied te markeren</i>
                            <button className="reset-button" onClick={()=>this.resetPoly()}>Herstel markering</button>
                            <br/><br/>
                            <label>Grondklasse: </label>
                            <br/>
                            <FormControlLabel control={ <Checkbox checked={this.state.checkUndifined} onChange={()=>this.handleChange("Niet bekend")} name="checkedB" color="primary"/>} label="Niet bekend"/>
                            <FormControlLabel control={ <Checkbox checked={this.state.checkAw} onChange={()=>this.handleChange("AW")} name="checkedB" color="primary"/>} label="AW2000"/>
                            <FormControlLabel control={ <Checkbox checked={this.state.checkW0} onChange={()=>this.handleChange("WO")} name="checkedB" color="primary"/>} label="Wonen"/>
                            <FormControlLabel control={ <Checkbox checked={this.state.checkInd} onChange={()=>this.handleChange("IND")} name="checkedB" color="primary"/>} label="Industrie"/>

                        </div>
                        <span className="input-field-request">
                        <label>Waar komt grond vrij: </label>
                            <br/>
                            <select onChange={(event)=> this.ComingFrom(event.target.value)}>
                                <option>Selecteer een optie</option>
                                <option value="bovengrond">Grond komt vrij uit bovengrond tot 50cm minus maaiveld</option>
                                <option value="ondergrond">Grond komt vrij uit ondergrond dieper dan 50cm minus maaiveld</option>
                                
                            </select>
                            <br/>
                        
                        </span>
                        
                        
                        <span className="input-field-request">
                        <label>Datum van aanvoeren: </label>
                            <br/>
                            <input required type="date" name="brining-date" value={this.state.date} onChange={event => this.setState({ date: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                        <label>Bijlage</label>
                            <br/>
                            <input type="file" onChange={(event) => this.handleUploadCompanyLogo(event)} />
                        </span>
                        <span className="input-field-request">
                            <h3>Voeg extra afvalstof toe</h3>
                         <br/>
                         <button className="add-asn" onClick={()=>this.addASN()}>Toevoegen</button>
                        </span><br/><br/>
                        </span>:null}
                        </span>



                       
                
                        

                 
                        
                        <br/>

                        <span className="input-field-request">
                            <label>Met wie hebt u contact gehad inzake deze aanvraag?</label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.companyContactOrigin} onChange={event => this.setState({ companyContactOrigin: event.target.value, errorMessage:''})}/>
                            <label style={{width:'100%',marginTop:'-5%!important'}}><input type="checkbox"  checked={this.state.noContactPerson} onChange={(e)=>this.setState({noContactPerson: e.target.checked,companyContactOrigin: e.target.checked ?'geen contactpersoon bekend':this.state.companyContactOrigin })}/>Er is nog geen contactpersoon bekend</label>

                        </span>
                        <br/>
                        <span className="input-field-request">
                            <h3>Overige informatie</h3>
                            <label style={{width:'100%',marginTop:'-2%'}}><input type="checkbox"  value={this.state.correct} onClick={() => this.Correct()}/>De gegevens van de herkomst van de grond correct zijn ingevuld. </label>
                          
                        </span>
                        
                      
                       
                        <span className="create-space">
                            .
                        </span>
                        </span>
                       
                        
                    </Col>
                    <Col sm="6" className="gegevens-row">
                   
                            <h3>Gegevens</h3>
                            <Row>
                            {this.state.companyNameType != '' ?
                                <Col sm={6} className="side-block-frontend-form side-block-6">
                                    <h4>Afzender/ ontdoener</h4>
                                <ul>
                                    {this.state.companyNameType != '' ? <li>{this.state.companyNameType}</li>:null}
                                    {this.state.companyName != '' ? <li>{this.state.companyName}</li>:null}
                                    {this.state.companyAddress != '' ? <li>{this.state.companyAddress} {+this.state.companyHouseNumber} {', '+this.state.companyPostalCity}  {', '+this.state.companyCity}</li>:null}
                                   
                                    {this.state.companyMail != '' ?  <li>{this.state.companyMail} </li>:null}

                                        
                                </ul>
                                </Col>:null}
                                {this.state.companyNameOrigin != '' ?
                                <Col sm={6}  className="side-block-frontend-form side-block-6">
                                <h4>Locatie van herkomst</h4>
                                    <ul>
                                    {this.state.companyNameOrigin != '' ?   <li>{this.state.companyNameOrigin}</li>:null}
                                    {this.state.companyAddressOrigin != '' ?   <li>{this.state.companyAddressOrigin} {this.state.companyAddressHousenumber}</li>:null}
                                    {this.state.companyPostalOrigin != '' ?   <li>{this.state.companyPostalOrigin} {this.state.companyCityOrigin}</li>:null}
                                    {this.state.companyContactOrigin != '' ?    <li>{this.state.companyContactOrigin}</li>:null}
                                </ul>
                                    
                                </Col>:null}
                                {this.state.typeName !='' || this.state.multipleASN ?
                                <Col sm={12}  className="side-block-frontend-form side-block-12">
                                <h4>Gegevens van afvalstof</h4>
                                {this.state.multipleASN?
                                        this.state.multipleASNS.map((question, i) =>(
                                        <Col sm={12} className="faq-col" onClick={()=>this.changeAccordion(i)}>
                                        <h5>{question.typeName}</h5>{question.open ? <i class="fas fa-chevron-up"></i>: <i class="fas fa-chevron-down"></i>}
                                        {question.open ? <Row className="faq-row-para">
                                            <Col sm={12}>
                                                <p>
                                                    <ul>
                                                        <li>Benaming:  {question.typeName}</li>
                                                        { question.amountTon?<li>Aantal ton:  {question.amountTon}</li>:null}
                                                        { question.groundClass? <li>Klasse:  {question.groundClass}</li>:null}
                                                        { question.comingFrom? <li>Komt vandaan van:  {question.comingFrom}</li>:null}
                                                        { question.information?<li>Informatie  {question.information}</li>:null}
                                                        { question.date? <li>Afvoer datum  {question.date}</li>:null}
                                                        { question.attachments? <li>Bijlage  {question.attachments}</li>:null}
                                                        </ul>
                                                
                                                    </p>
                                            </Col>
                                        </Row>:null}
                                    </Col>

                                )):

                                    <ul>
                                        {this.state.typeName != '' ?   <li>{this.state.typeName}</li>:null}
                                    {this.state.companyNameOrigin != '' ?   <li>{this.state.companyNameOrigin}</li>:null}
                                    {this.state.companyAddressOrigin != '' ?   <li>{this.state.companyAddressOrigin}</li>:null}
                                    {this.state.companyPostalOrigin != '' ?   <li>{this.state.companyPostalOrigin}</li>:null}
                                    {this.state.companyContactOrigin != '' ?    <li>{this.state.companyContactOrigin}</li>:null}
                                </ul>}
                                    
                                </Col>:null}
                                {this.state.typePayment != '' ?
                                <Col sm={12}  className="side-block-frontend-form side-block-12">
                                <h4>Overige informatie</h4>
                                    <ul>
                                    {this.state.typePayment != '' ?   <li>Betalmethode: {this.state.typePayment}</li>:null}
                                    {this.state.companyContactOrigin != '' ?   <li>Contactpersoon Dirix: {this.state.noContactPerson ? 'Geen eerder contact':this.state.companyContactOrigin}</li>:null}
                                </ul>
                                    
                                </Col>:null}

                            </Row>
                         
                 
                            
                          
                           
                         <span className="input-field-request">
                         {this.state.loader?   <button className="submit-button-form"><Loader type="Bars" color="#fff" height={20} width={20} /></button>
                          : <button disabled={this.state.companyContactOrigin !='' ? false: true}  className={this.state.companyContactOrigin !='' ? "submit-button-form ": "submit-button-form disabled"} onClick={()=>this.sendRequest()}>Verzend aanvraag</button>}
                        </span>
                      

                       
                       
                    </Col>
                </Row>
:

                <Row>
               
                    <Col sm="6" className="request-form">
                        <h3>Afzender / ontdoener</h3>
                        <p>
                            <i><b>Let op!</b> U vraag een algemeen afvalstroomnummer aan om te gebruiken op meerder projectlocaties. Het is niet mogelijk een algemeen ASN aan te vragen voor de volgende afvalstoffen: 
                            <ul>
                                <li>Grond</li>
                                <li>Asfalt</li>
                                <li>Asbest</li>
                            </ul></i>
                        </p>
                        <span className="input-field-request">
                        <label>Particulier of zakelijk: </label>
                            <br/>
                            <select onChange={(event)=> this.setState({companyNameType: event.target.value})}>
                                <option >Selecteer een type</option>
                                <option value="Particulier">Particulier</option>
                                <option value="Zakelijk">Zakelijk</option>
                            </select>
                            <br/>
                            {this.state.companyNameType == 'Particulier'?   
                            <span className="input-field-request">
                                <label>Voor + achternaam: </label>
                                <br/>
                                <input required type="text" name="company-name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})}/>
                                </span> :null}
                                {this.state.companyNameType == 'Zakelijk'?   
                            <span className="input-field-request">
                                <label>Naam bedrijf: </label>
                                <br/>
                                <input required type="text" name="company-name" value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})}/>
                                </span> :null}
                        </span>
                        <span className="input-field-request">
                            <label>Straatnaam: </label>
                            <br/>
                            <input required type="text" name="company-adres" value={this.state.companyAddress} onChange={event => this.setState({ companyAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Huisnummer: </label>
                            <br/>
                            <input required type="text" name="company-adres" value={this.state.companyHouseNumber} onChange={event => this.setState({ companyHouseNumber: event.target.value, errorMessage:''})}/>
                            
                        </span>
                        <span className="input-field-request">
                            <label>Postcode: </label>
                            <br/>
                            <input required type="text" name="company-postal-city" value={this.state.companyPostalCity} onChange={event => this.setState({ companyPostalCity: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Plaats: </label>
                            <br/>
                            <input required type="text" name="company-postal-city" value={this.state.companyCity} onChange={event => this.setState({ companyCity: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>E-mail </label>
                            <br/>
                            <input required type="text" name="company-mail" value={this.state.companyMail} onChange={event => this.setState({ companyMail: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Telefoon</label>
                            <br/>
                            <input required type="text" name="company-phone" value={this.state.companyPhone} onChange={event => this.setState({ companyPhone: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Naam contactpersoon</label>
                            <br/>
                            <input required type="text" name="company-phone" value={this.state.companyContact} onChange={event => this.setState({ companyContact: event.target.value, errorMessage:''})}/>

                        </span>
                        <span className="input-field-request">
                       {/*}     <label style={{width:'50%'}}><input type="checkbox"  value={this.state.differentPaymentAddress} onClick={() => this.DifferentPaymentAddress()}/> Afwijkend factuuradres </label>
                            {this.state.differentPaymentAddress ? null:  <button className="next-button-form" onClick={()=>this.setState({secondBlock:true})}>Volgende</button>*/}
                        </span>

{this.state.differentPaymentAddress ?<span>
                        <h3>Factuuradres</h3>
                        <span className="input-field-request">
                            <label>Naam bedrijf: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubName} onChange={event => this.setState({ companySubName: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Factuuradres + huisnummer: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubAddress} onChange={event => this.setState({ companySubAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            <label>Factuurpostcode + vestigingplaats: </label>
                            <br/>
                            <input required type="text" name="name-client" value={this.state.companySubPaymentAddress} onChange={event => this.setState({ companySubPaymentAddress: event.target.value, errorMessage:''})}/>
                        </span>
                        <span className="input-field-request">
                            
                            <button className="next-button-form" onClick={()=>this.setState({secondBlock:true})}>Volgende</button>
                        </span>
                        <br/><br/>   <br/><br/>
                        </span>:null}
                            <hr/>
                            <span className={"is-active"}>
                        <h3>Locatie van herkomst</h3>
                        <span className="input-field-request">
                            <p>
                            U heeft gekozen voor het aanvragen van een afvalstroomnummer om te gebruiken op meerdere projectlocaties. U vermeldt daarom op de begeleidingsbrieven de projectgegevens en het bijbehorend adres.
                            </p>
                        </span>
                        <span className="input-field-request">{/*}
                             <button className="next-button-form" onClick={()=>this.setState({thirdBlock:true})}>Volgende</button>
*/}
                        </span>
                        <br/><br/><br/>
                        <hr/>
                        </span>
                    
                         <span className={"is-active"}>
                         <h3>Gegevens van afvalstof</h3>
                      {this.state.multipleASN?
                         this.state.multipleASNS.map((question, i) =>(
                           <Col sm={12} className="faq-col" onClick={()=>this.changeAccordion(i)}>
                           <h5>{question.typeName}</h5>{question.open ? <i class="fas fa-chevron-up"></i>: <i class="fas fa-chevron-down"></i>}
                          {question.open ? <Row className="faq-row-para">
                               <Col sm={12}>
                                   <p>
                                       <ul>
                                           <li>Type ASN:  {question.typeName}</li>
                                           
                                        </ul>
                                  
                                    </p>
                               </Col>
                           </Row>:null}
                       </Col>

                )):null}


                                     
                         <span> 
                            <span className="input-field-request">
                        <label>Type afvalstof: </label>
                            <br/>
                            <select  value={this.state.typeName} onChange={(event)=> this.typeAsn(event.target.value)}>
                                <option >Selecteer een type</option>
                                <option value="Hout">Hout</option>
                                <option value="BSA">BSA</option>
                                <option value="Puin">Puin</option>
                                <option value="Groenafval">Groenafval</option>
                                <option value="Dakleer">Dakleer</option>
                                <option value="C-hout">C-hout</option>
                                

                            
                            </select>
                            <br/>
                            {this.state.informationDifferent?  <input  type="text" className="new-input-field" name="amount-ton" value={this.state.informationElse} onChange={event => this.setState({ informationElse: event.target.value, errorMessage:''})}/> :null}
                        </span>
                    {this.state.typeForm && this.state.typeSet ? 
                    <span>
                       
                        <span className="input-field-request">
                            <h3>Voeg extra afvalstof toe</h3>
                         <br/>
                         <button className="add-asn" onClick={()=>this.addASN()}>Toevoegen</button>
                        </span><br/><br/>
                    
                    </span>

                    :
                    this.state.typeSet?
                    <span>
                        
                        
                        
                        <span className="input-field-request">
                            <h3>Voeg extra afvalstof toe</h3>
                         <br/>
                         <button className="add-asn" onClick={()=>this.addASN()}>Toevoegen</button>
                        </span><br/><br/>
                        </span>:null}
                        </span>



                       
               
                        

                 
                        
                        <br/>

                        <span className="input-field-request">
                            <label>Met wie hebt u contact gehad inzake deze aanvraag?</label>
                            <br/>
                            <input required type="text" name="amount-ton" value={this.state.companyContactOrigin} onChange={event => this.setState({ companyContactOrigin: event.target.value, errorMessage:''})}/>
                            <label style={{width:'100%',marginTop:'-5%!important'}}><input type="checkbox"  checked={this.state.noContactPerson} onChange={()=>this.setState({noContactPerson: this.state.noContactPerson ? false: true,companyContactOrigin:this.state.noContactPerson?'geen contactpersoon bekend':this.state.companyContactOrigin })}/>Er is nog geen contactpersoon bekend</label>

                        </span>
                        <br/>
                        <span className="input-field-request">
                            <h3>Overige informatie</h3>
                            <label style={{width:'100%',marginTop:'-2%'}}><input type="checkbox"  value={this.state.correct} onClick={() => this.Correct()}/>De gegevens van de herkomst van de grond correct zijn ingevuld. </label>
                          
                        </span>
                        
                      
                       
                        <span className="create-space">
                            .
                        </span>
                        </span>
                       
                        
                    </Col>
                    <Col sm="6" className="gegevens-row">
                   
                            <h3>Gegevens</h3>
                            <Row>
                            {this.state.companyNameType != '' ?
                                <Col sm={6} className="side-block-frontend-form side-block-6">
                                    <h4>Afzender/ ontdoener</h4>
                                <ul>
                                    {this.state.companyNameType != '' ? <li>{this.state.companyNameType}</li>:null}
                                    {this.state.companyName != '' ? <li>{this.state.companyName}</li>:null}
                                    {this.state.companyAddress != '' ? <li>{this.state.companyAddress}</li>:null}
                                    {this.state.companyPostalCity != '' ?  <li>{this.state.companyPostalCity}</li>:null}
                                    {this.state.companyMail != '' ?  <li>{this.state.companyMail}</li>:null}
                                    {this.state.companyPhone != '' ?  <li>{this.state.companyPhone}</li>:null}
                                    {this.state.companyContact != '' ?  <li>{this.state.companyContact}</li>:null}
                                        
                                </ul>
                                </Col>:null}
                                {this.state.companyNameOrigin != '' ?
                                <Col sm={6}  className="side-block-frontend-form side-block-6">
                                <h4>Locatie van herkomst</h4>
                                    <ul>
                                    {this.state.companyNameOrigin != '' ?   <li>{this.state.companyNameOrigin}</li>:null}
                                    {this.state.companyAddressOrigin != '' ?   <li>{this.state.companyAddressOrigin}</li>:null}
                                    {this.state.companyPostalOrigin != '' ?   <li>{this.state.companyPostalOrigin}</li>:null}
                                    {this.state.companyContactOrigin != '' ?    <li>{this.state.companyContactOrigin}</li>:null}
                                </ul>
                                    
                                </Col>:null}
                                {this.state.typeName !='' || this.state.multipleASN ?
                                <Col sm={12}  className="side-block-frontend-form side-block-12">
                                <h4>Gegevens van afvalstof</h4>
                                {this.state.multipleASN?
                                        this.state.multipleASNS.map((question, i) =>(
                                        <Col sm={12} className="faq-col" onClick={()=>this.changeAccordion(i)}>
                                        <h5>{question.typeName}</h5>{question.open ? <i class="fas fa-chevron-up"></i>: <i class="fas fa-chevron-down"></i>}
                                        {question.open ? <Row className="faq-row-para">
                                            <Col sm={12}>
                                                <p>
                                                    <ul>
                                                        <li>Benaming:  {question.typeName}</li>
                                                        { question.amountTon?<li>Aantal ton:  {question.amountTon}</li>:null}
                                                        { question.groundClass? <li>Klasse:  {question.groundClass}</li>:null}
                                                        { question.comingFrom? <li>Komt vandaan van:  {question.comingFrom}</li>:null}
                                                        { question.information?<li>Informatie  {question.information}</li>:null}
                                                        { question.date? <li>Afvoer datum  {question.date}</li>:null}
                                                        { question.attachments? <li>Bijlage  {question.attachments}</li>:null}
                                                        </ul>
                                                
                                                    </p>
                                            </Col>
                                        </Row>:null}
                                    </Col>

                                )):

                                    <ul>
                                        {this.state.typeName != '' ?   <li>{this.state.typeName}</li>:null}
                                    {this.state.companyNameOrigin != '' ?   <li>{this.state.companyNameOrigin}</li>:null}
                                    {this.state.companyAddressOrigin != '' ?   <li>{this.state.companyAddressOrigin}</li>:null}
                                    {this.state.companyPostalOrigin != '' ?   <li>{this.state.companyPostalOrigin}</li>:null}
                                    {this.state.companyContactOrigin != '' ?    <li>{this.state.companyContactOrigin}</li>:null}
                                </ul>}
                                    
                                </Col>:null}
                                {this.state.typePayment != '' ?
                                <Col sm={12}  className="side-block-frontend-form side-block-12">
                                <h4>Overige informatie</h4>
                                    <ul>
                                    {this.state.typePayment != '' ?   <li>Betalmethode: {this.state.typePayment}</li>:null}
                                    {this.state.companyContactOrigin != '' ?   <li>Contactpersoon Dirix: {this.state.noContactPerson ? 'Geen eerder contact':this.state.companyContactOrigin}</li>:null}
                                </ul>
                                    
                                </Col>:null}

                            </Row>
                         
                 
                            
                          
                           
                         <span className="input-field-request">
                         {this.state.loader?   <button className="submit-button-form"><Loader type="Bars" color="#fff" height={20} width={20} /></button>
                          : <button disabled={this.state.companyContactOrigin !='' ? false: true}  className={this.state.companyContactOrigin !='' ? "submit-button-form ": "submit-button-form disabled"} onClick={()=>this.sendRequest()}>Verzend aanvraag</button>}
                        </span>
                      

                       
                       
                    </Col>
                </Row>}
                
                </Col>
               
                    
   
            </Row>
       



        );
    }
};

export default RequestsFrontendIndex;