import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';

class RequestsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[]
        };
    }



    componentDidMount() {
        this.getRequests();
    }

    getRequests(){
    
        let ref = Firebase.database().ref('/ASN/requests/');
        ref.on('value' , snapshot => {
            this.setState({
                requests:[]
            })
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

            console.log(new Date((state.requestDate.split('-')[2]),(state.requestDate.split('-')[1]),(state.requestDate.split('-')[0])))
         
          
                this.setState(prevState => ({
                    requests: [...prevState.requests, state],
                }))  
            })
        });

    }

    ArchiveItem(item){
        let today = new Date();
        Firebase.database().ref('/ASN/requests/'+item.id).update({
   
            requestStatus: 'done',
         })

        Firebase.database().ref('/ASN/requests/'+item.id+'/confirm').update({
   
            asn: '-',
            eural: 'n.v.t.',
            date: today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),
        })

        Firebase.database().ref('/ASN/requests/'+item.id+'/price').update({
   
            check: 'canceled',
            rating: 'Afgekeurd',
     
            priceNavision: '-',
            priceName:'-',
            number:'-',
            attachment:'-'

        
        })

        Firebase.database().ref('/ASN/requests/'+item.id+'/rating').update({
   
            check:  'canceled',
            rating: 'Afgekeurd',
            name:'-',
            nameAsn:'-',
            groundClassIntern:item.groundClass != ''? item.groundClass:'geen grond',
            ratingText: '-',
            brl9335:'-',
            pre:''
            
        
    })

  

    }

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12} className="dirix-button">
            <Link className="dirix-default-button " to={'../mijn-aanvraag'}>Toevoegen ASN</Link>
            <br/>
        </Col>
            <Col sm={12} className="products-table new-table mt-10">
                <h3>Nieuwe aanvragen</h3>
                <table>
                    <tr>
                        <th>Datum aanvraag</th>
                        <th>Naam</th>
                        <th>Afvalstof</th>
                        <th>Project adres</th>
                        <th>Beoordeling</th>
                        <th>Prijs</th>
                        <th>ASN</th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests.sort((a,b)=>{return new Date((b.requestDate.split('-')[2]),(b.requestDate.split('-')[1]),(b.requestDate.split('-')[0])) - new Date((a.requestDate.split('-')[2]),(a.requestDate.split('-')[1]),(a.requestDate.split('-')[0]))}).filter((item)=> item.requestStatus == 'new').map((item)=>(
                <tr >
                    <td>{item.requestDate}</td>
                    <td>{item.companyName}</td>
                    <td>{item.type}  <br/><i>{item.groundClass != 'geen grond' ? item.rating.groundClassIntern:null}</i></td>
                    <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }, {item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.price.check == 'in progress'?<i class="far fa-clock"></i>:null }{item.price.check == 'canceled'?<i class="fas fa-times"></i>:null }{item.price.check == 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.confirm == null ? <i class="far fa-clock"></i>:<i class="fas fa-check"></i> }</td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link> <button className="archive-button-table" onClick={()=>this.ArchiveItem(item)}><i class="fas fa-trash"></i></button></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>
            <Col sm={12} className="products-table running-table">
            <h3>Aanvragen in behandeling</h3>
                <table>
                    
                    <tr>
                        <th>Datum aanvraag</th>
                        <th>Naam</th>
                        <th>Afvalstof</th>
                        <th>Project adres</th>
                        <th>Beoordeling</th>
                        <th>Prijs</th>
                        <th>ASN</th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests.sort((a,b)=>{return new Date((b.requestDate.split('-')[2]),(b.requestDate.split('-')[1]),(b.requestDate.split('-')[0])) - new Date((a.requestDate.split('-')[2]),(a.requestDate.split('-')[1]),(a.requestDate.split('-')[0]))}).filter((item)=> item.requestStatus != 'new').filter((item)=> item.confirm == undefined).map((item)=>(
                <tr >
                    <td>{item.requestDate}</td>
                    <td>{item.companyName}</td>
                    <td>{item.type}  <br/><i>{item.groundClass != 'geen grond' ? item.rating.groundClassIntern:null}</i></td>
                    <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }, {item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.price.check == 'in progress'?<i class="far fa-clock"></i>:null }{item.price.check == 'canceled'?<i class="fas fa-times"></i>:null }{item.price.check == 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.confirm == null ? <i class="far fa-clock"></i>:<i class="fas fa-check"></i> }</td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link> <button className="archive-button-table" onClick={()=>this.ArchiveItem(item)}><i class="fas fa-trash"></i></button></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>
            <Col sm={12} className="products-table done-table">
            <h3>Afgeronde aanvragen</h3>
                <table>
                <tr>
                        <th>Datum aanvraag</th>
                        <th>Naam</th>
                        <th>Afvalstof</th>
                        <th>Project adres</th>
                        <th>Beoordeling</th>
                        <th>Prijs</th>
                        <th>ASN</th>
                        <th>Actie</th>
                    </tr>
    
           {this.state.requests.sort((a,b)=>{return new Date((b.requestDate.split('-')[2]),(b.requestDate.split('-')[1]),(b.requestDate.split('-')[0])) - new Date((a.requestDate.split('-')[2]),(a.requestDate.split('-')[1]),(a.requestDate.split('-')[0]))}).filter((item)=> item.confirm != null).filter((item)=> item.requestStatus != 'new').filter((item)=> item.rating.check != 'canceled').slice(0,10).map((item)=>(
                <tr >
                     <td>{item.requestDate}</td>
                    <td>{item.companyName}</td>
                    <td>{item.type} <br/><i>{item.groundClass != 'geen grond' ? item.rating.groundClassIntern:null}</i></td>
                    <td>{item.companyAddressOrigin == ''?'Meerdere locaties':item.companyAddressOrigin }, {item.companyPostalOrigin== ''?null:item.companyPostalOrigin}</td>
                    <td>{item.rating.check === 'in progress'?<i class="far fa-clock"></i>:null }{item.rating.check === 'canceled'?<i class="fas fa-times"></i>:null }{item.rating.check === 'agreed'?<i class="fas fa-check"></i>:null }</td>
                    <td>{item.price.check == 'in progress'?<i class="far fa-clock"></i>:null }{item.price.check == 'canceled'?<i class="fas fa-times"></i>:null }{item.price.check == 'agreed'?<i class="fas fa-check"></i>:null }</td>
                   
                    <td>{item.confirm == null ? <i class="far fa-clock"></i>: item.confirm.asn != '-' ?<i class="fas fa-check"></i>:<i class="fas fa-times"></i> }</td>
                    <th><Link to={{pathname: '/aanvragen/details', state:{data: item}}}>Bekijk</Link></th>
                </tr>
           ))}
                       
           
                    
                    
                </table>
    </Col>
                    
   
            </Row>
            </div>



        );
    }
};

export default RequestsIndex;