import React, { Component, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MapContainer  from './MapContainer';
import toast, { Toaster } from 'react-hot-toast';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import  MapContainerBackend  from './MapContainerBackend';

class RequestDetailsBackend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests:[],desc:'',
            productDesc:'',
            price:'',
            priceName:'',
            priceMail:'',
            pricePhone:'',
            price:'',
            ratingName:'',
            ratingMail:'',
            ratingPhone:'',
            rating:'',
            asn:'',
            eural:'',
            nameProject:'',
            locationProject: '',
            postalProject:'',
            amountTon:'',
            groundClass:'',
            comingFrom: '',
            information: '',
            date:'',
            contactPerson:'',
            attachment:'',
            brl9335:'',
            ratingStatus:'',
            username:'',
            commentText:'',
            status:[],
            priceAttachment:'',
            tempUrlPrice:'',
            priceNameOfferte:'',
            nameAsn:'',
            groundClassIntern:'',
            ratingText:'',
            priceNavision:'',
            pre:'',
            requestDate:'',
            companyPhone:'',
    companyMail:'',
    companyAddress:'',
    companyPostalCity:'',
    markers:[],
    companyAddressOrigin:'',
    companyPostalOrigin:'',
    companyNameOrigin:'',
    amountTon:'',
    companyNameContactPerformer:'',
    companyNamePhonePerformer:''
           
        };
    
    }
    

    getStatus(){
        let ref = Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/status/');
        ref.on('value' , snapshot => {
            this.setState({status:[]})
           
            snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                this.setState(prevState => ({
                    status: [...prevState.status, state]
                })) 

            })

        })
        
    }
    


    componentDidMount() {
        this.setState({
            markers:[]
        })

        this.getStatus();
        if(Firebase.auth().currentUser.uid === 'm9VWy6ccYFeMmAY5znl2ffAiuyn2'){
            this.setState({username: 'Joppe Meijers'})
        }else if(Firebase.auth().currentUser.uid === '1xMT7h5SK4dbJXVFcVRCH8JhaaB2'){
            this.setState({username: 'Tommie Amalo'})
        }else if(Firebase.auth().currentUser.uid === 'XcUnjMu5jCOTW2mlBe57sKgKXm93'){
            this.setState({username: 'Harold Maassen '})
        }
        else if(Firebase.auth().currentUser.uid === 'YhOJKhgPXNeih2xlNbB6ynCtoHW2'){
            this.setState({username: 'Erik Pepels'})
        }
        else if(Firebase.auth().currentUser.uid === 'T1D7CxchvQcfasl4HEODoek1EEL2'){
            this.setState({username: 'Mark Hermans'})
        }else if(Firebase.auth().currentUser.uid === 'iDmiX8fXAWNX2dt8YwOt0lQKrPz2'){
            this.setState({username: 'Erwin van den Broek'})
        }else if(Firebase.auth().currentUser.uid === 'RnIYWlNMSdMwOfZc0GEYU80iHPo1'){
            this.setState({username: 'Iris Kerstges'})
        }else if(Firebase.auth().currentUser.uid === 'GQvQvpLJypWhbxaC32dY0Kph43P2'){
            this.setState({username: 'Roel Joosten'})
        }else if(Firebase.auth().currentUser.uid === 'mlmYrvJWW7bjLE8Jxy0U7goFD8U2'){
            this.setState({username: 'Danielle Schwillens'})
        }else if(Firebase.auth().currentUser.uid === 'PD7fu19yMYWvhGB3cptyIJrgoTe2'){
            this.setState({username: 'Kevin Cuppens'})
        }else if(Firebase.auth().currentUser.uid === 'kfAUdf8tuZYsnQn9EzO0CZ3lebB3'){
            this.setState({username: 'Emile Verburg'})
        }

console.log(this.props.data.data.id)
this.setState({
    desc: this.props.data.data.information,
    priceName:this.props.data.data.price.priceName,
    priceAttachment:this.props.data.data.price.attachment,
    priceMail:this.props.data.data.price.mail,
    pricePhone:this.props.data.data.price.mail,
    price:this.props.data.data.price.rating,
    ratingName:this.props.data.data.rating.name,
    ratingMail:this.props.data.data.rating.mail,
    ratingPhone:this.props.data.data.rating.mail,
    rating:this.props.data.data.rating.rating,
    ratingStatus:this.props.data.data.rating.check,
    nameProject:this.props.data.data.companyNameOrigin,
    companyNameOrigin: this.props.data.data.companyNameOrigin,
    locationProject: this.props.data.data.companyAddressOrigin,
    companyAddressOrigin:this.props.data.data.companyAddressOrigin,
    companyPostalOrigin:this.props.data.data.companyPostalOrigin,
    companyNameOrigin: this.props.data.data.companyNameOrigin,
    amountTon:this.props.data.data.amountTon,
    postalProject:this.props.data.data.companyPostalOrigin ,
    amountTon:this.props.data.data.amountTon,
    groundClass:this.props.data.data.groundClass,
    comingFrom: this.props.data.data.comingFrom,
    information: this.props.data.data.information,
    date:this.props.data.data.date,
    companyNameContactPerformer:this.props.data.data.companyNameContactPerformer,
    companyNamePhonePerformer:this.props.data.data.companyNamePhonePerformer,
    contactPerson: this.props.data.data.companyContactOrigin,
    attachment:this.props.data.data.attachments,
    priceNameOfferte: this.props.data.data.price.number != undefined ?this.props.data.data.price.number:'',
    nameAsn:  this.props.data.data.rating.nameAsn,
    groundClassIntern :this.props.data.data.rating.groundClassIntern != undefined ? this.props.data.data.rating.groundClassIntern:'',
    brl9335: this.props.data.data.rating.brl9335 != undefined ? this.props.data.data.rating.brl9335:'',
    pre: this.props.data.data.rating.pre != undefined ? this.props.data.data.rating.pre:'',
    ratingText:this.props.data.data.rating.ratingText != undefined ? this.props.data.data.rating.ratingText:'',
    price:this.props.data.data.price.rating ,
    priceNavision: this.props.data.data.price.priceNavision,
    requestDate: this.props.data.data.requestDate,
    asn: this.props.data.data.confirm != undefined ? this.props.data.data.confirm.asn:'',
    eural: this.props.data.data.confirm != undefined ? this.props.data.data.confirm.eural:'',
    companyName:this.props.data.data.companyName,
    contactPersonRequest:this.props.data.data.contactPersonRequest,
    companyPhone:this.props.data.data.companyPhone,
    companyMail:this.props.data.data.companyMail,
    companyAddress:this.props.data.data.companyAddress,
    companyPostalCity:this.props.data.data.companyPostalCity,
    markers:this.props.data.data.markers != undefined ?this.props.data.data.markers:'geen-markers'
    

})


    }

    updateGeneralItems(){
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
            companyName: this.state.companyName ,
            contactPersonRequest: this.state.contactPersonRequest,
            companyPhone:this.state.companyPhone,
            companyMail:this.state.companyMail,
            companyAddress:this.state.companyAddress ,
            companyPostalCity:this.state.companyPostalCity,
          
            
        
    }).then(()=>{
        toast.success('Gegevens bijgewerkt')
    })
    }

    updateLocationItems(){
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
            companyAddressOrigin: this.state.companyName ,
            companyAddressOrigin: this.state.contactPersonRequest,
            companyNameOrigin:this.state.companyPhone,
            
    
            
        
    }).then(()=>{
        toast.success('Gegevens bijgewerkt')
    })
    }

    UpdateRating(){
        

        if(this.state.rating === 'goedgekeurd'){
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/rating').update({
   
                check: this.state.rating ==='goedgekeurd' ? 'agreed': 'canceled',
                rating: this.state.rating,
                name:this.state.username,
                nameAsn:this.state.nameAsn,
                groundClassIntern:this.state.groundClassIntern != ''? this.state.groundClassIntern:'geen grond',
                ratingText:this.state.ratingText,
                brl9335:this.state.brl9335 != '' ?this.state.brl9335 :'geen grond',
                pre:this.state.pre != ''? this.state.pre: 'geen grond'
                
            
        }).then(()=>{
            this.addComment('description');
            let headers = new Headers();

            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','http://localhost:3000');
            //headers.append('Origin','https://frontend-test-bbo.web.app/')
            headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
            fetch("https://us-central1-dirix-bv.cloudfunctions.net/CheckTwoSubmit", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
              })
              .then(res => {
                toast.success('Beoordeling is opgeslagen');
              })
        })

        }else{

            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/rating').update({
   
                check: this.state.rating ==='goedgekeurd' ? 'agreed': 'canceled',
                rating: this.state.rating,
                name:this.state.username,
                nameAsn:this.state.nameAsn,
                groundClassIntern:this.state.groundClassIntern != ''? this.state.groundClassIntern:'geen grond',
                ratingText:this.state.ratingText,
                brl9335:this.state.brl9335 != '' ?this.state.brl9335 :'geen grond',
                pre:this.state.pre != ''? this.state.pre: 'geen grond'
                
            
        }).then(()=>{
            this.addComment('description');
           
        })
        }
   
    }

    UpdatePrice(){
        if(this.state.price === 'goedgekeurd'){
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
   
              check: this.state.price ==='goedgekeurd' ? 'agreed': 'canceled',
              rating: this.state.price,
       
              priceNavision: this.state.priceNavision,
              priceName:this.state.priceName != ''? this.state.priceName:'-',
              number: this.state.priceNameOfferte  != ''? this.state.priceNameOfferte:'-',
              attachment:this.state.attachment,

          
      }).then(()=>{
        this.addComment('euro');
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-dirix-bv.cloudfunctions.net/MailToRos", {
            method: "POST",
            headers: headers,
              body: JSON.stringify({companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
          })
          .then(res => {
            toast.success('Prijs is geupdate & mail verstuurd');
          })
      })

        }else{

   
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
   
              check: this.state.price ==='goedgekeurd' ? 'agreed': 'canceled',
              rating: this.state.price,
       
              priceNavision: this.state.priceNavision,
              priceName:this.state.priceName != ''? this.state.priceName:'-',
              number: this.state.priceNameOfferte  != ''? this.state.priceNameOfferte:'-',
              attachment:this.state.attachment,

          
      }).then(()=>{
       
            toast.success('Prijs is geupdate');
          
      })
    
    }
    }

    addComment(type){
        let today = new Date();
        var key = Firebase.database().ref().child('/ASN/requests/'+this.props.data.data.id+'/status/').push().key;

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/status/'+key).set({
   
            key: key,
            date: today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),
            text: this.state.commentText,
            user: this.state.username,
            type:type
        }).then(()=>{
            this.setState({
                commentText:''
            })
            toast.success('Opmerking opgeslagen')
        })

        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
            requestStatus: 'running',
         })
    }

    UpdateASN(){
        let today = new Date();
        if(this.state.price === 'verstuur'){
            console.log('mail voor aanvrager wordt verstuurd')

        }else{

   
            Firebase.database().ref('/ASN/requests/'+this.props.data.data.id).update({
   
                requestStatus: 'running',
             })
        Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/confirm').update({
   
              asn: this.state.asn,
              eural: this.state.eural,
              date: today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),
              

          
      }).then(()=>{
        this.addComment('pin');
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-dirix-bv.cloudfunctions.net/AccomplishEmailClient", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({email: this.props.data.data.companyMail, companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddressOrigin,postal:  this.props.data.data.companyPostalOrigin, asn: this.state.asn,groundClass : this.state.groundClassIntern,eural:this.state.eural})
          })
          .then(res => {
            toast.success('ASN-nummer is toegevoegd');
          })
      })
    
    }
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     sendAdministrationRequest(){
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://frontend-test-bbo.web.app/')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-dirix-bv.cloudfunctions.net/sendAdministrationRequest", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({companyName: this.props.data.data.companyName, companyName:  this.props.data.data.companyName ,adres:  this.props.data.data.companyAddress,postal:  this.props.data.data.companyPostalCity})
          })
          .then(res => {
            toast.success('Mail is verstuurd');
          })
     }


    handleUploadCompanyLogo(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
 
    


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Dirix/attachments/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Dirix/attachments/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Document opgeslagen');
                  Firebase.database().ref('/ASN/requests/'+this.props.data.data.id+'/price').update({
                    attachment: url,
      
                

                    })

                    this.setState({
                        tempUrlPrice:url
                    })
               
                }).then(()=>{
                     

                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }


    render() {
    
        return (
            <div>
                          <Toaster/>
                <Row className="col-sm-12">
                    <Col sm="8" className="request-detail-8">
                    <h3>Afzender / ontdoener</h3>
                    <div className="request-detail-row">
                            <span className="request-detail-col-left">Datum aanvraag</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.requestDate}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam</span>
                            <span className="request-detail-col-right align-text-right"> <input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyName} onChange={(event) => this.setState({companyName:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam contactpersoon</span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.contactPersonRequest} onChange={(event) => this.setState({contactPersonRequest:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Telefoon</span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyPhone} onChange={(event) => this.setState({companyPhone:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">E-mail</span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyMail} onChange={(event) => this.setState({companyMail:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Adres</span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyAddress} onChange={(event) => this.setState({companyAddress:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Postcode + Woonplaats</span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyPostalCity} onChange={(event) => this.setState({companyPostalCity:event.target.value})} /></span>
                            <hr/> 
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Afvalstof</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.type}</span>
                            <hr/> 
                        </div>
                        <div className="request-detail-row">
                        <span className="request-detail-col-left"></span>
                            <span className="request-detail-col-right align-text-right"><button onClick={()=>this.updateGeneralItems()} className="dirix-default-button small-button smaller-button">Opslaan</button></span>
                        
                        </div>
                        <div className="location-table">
                            
                        <h3>Locatie van herkomst</h3>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Naam / project: </span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyNameOrigin} onChange={(event) => this.setState({companyNameOrigin:event.target.value})} /></span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Straat + huisnr (geen postbus): </span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyAddressOrigin} onChange={(event) => this.setState({companyAddressOrigin:event.target.value})} /></span>
                            <hr/>
                           
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Postcode plaats: </span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.companyPostalOrigin} onChange={(event) => this.setState({companyAddressOrigin:event.target.value})} /></span>
                            <hr/>
                            <span className="request-detail-col-left"></span>
                            <span className="request-detail-col-right align-text-right"><button onClick={()=>this.updateLocationItems()} className="dirix-default-button small-button smaller-button">Opslaan</button></span>
                        

                            
                        </div></div>
                        
                      
                        {this.props.data.data.type == 'Grond'?
                        <span>
                        <h3>Gegevens grond</h3>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Hoeveelheid (M3 / ton): </span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.amountTon} onChange={(event) => this.setState({amountTon:event.target.value})} /> {this.props.data.data.amountType}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Waar komt grond vrij: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.comingFrom == 'ondergrond' ?'Grond komt vrij uit ondergrond dieper dan 50cm minus maaiveld': 'Grond komt vrij uit bovengrond tot 50cm minus maaiveld'}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Voor info:  </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.information}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Datum van aanvoeren: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.date}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Contactpersoon DIRIX: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.companyContactOrigin}</span>
                            <hr/>
                        </div>
                        
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Bijlage</span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.attachments != '' ?<a target="_blank" href={this.props.data.data.attachments}><i class="fas fa-file-alt"> {this.props.data.data.attachments}</i></a>: 'Geen bijlage'}</span>
                            <hr/>
                        </div>
                        </span>:
                        
                        <span>
                        <h3>Gegevens afvalstof</h3>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Afvalstof: </span>
                            <span className="request-detail-col-right align-text-right">{this.props.data.data.type}</span>
                            <hr/>
                        </div>
                        <div className="request-detail-row">
                            <span className="request-detail-col-left">Hoeveelheid: </span>
                            <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="text"  value={this.state.amountTon} onChange={(event) => this.setState({amountTon:event.target.value})} /> {this.props.data.data.amountType}</span>
                            <hr/>
                        </div>
                        </span>}
                    </Col>
                    <Col sm="4" className="request-detail-4">
                        <Row>
                            <Col sm="12" className="price-block status-block">
                                <h3>Status aanvraag</h3>
                                <Timeline>
                                {this.state.status.map((item)=>(
                                  
                                    <TimelineEvent title={"Opmerking van "+ item.user} createdAt={item.date}  icon={<i className="material-icons md-20">{item.type}</i>}>
                                        {item.type == 'euro' ? 'De prijs is beoordeeld':null} {item.type == 'sms' ?item.text:null} {item.type == 'pin' ? 'Er is een ASN wijziging gedaan':null} {item.type == 'description' ? 'Beoordeiling is gewijzigd':null} 
                                    </TimelineEvent>
                              
                                ))}
                                </Timeline>

                                
                                <textarea value={this.state.commentText} onChange={(event)=> this.setState({commentText:event.target.value})}/>
                                <button onClick={()=>this.addComment('sms')} className="dirix-default-button small-button">Opslaan</button>

                        
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" className="requests-block">
                            <h3>ASN-nummer</h3>
                                
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">ASN-nummer</span>
                                    <span className="request-detail-col-right align-text-right small-input"><input type="text" minlength="12" maxlength="12" value={this.state.asn} onChange={(event)=>this.setState({asn:event.target.value})}/></span>
                                    <hr/>
                                </div>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Eural-code</span>
                                    <span className="request-detail-col-right align-text-right ">
                                        
                                        <select value={this.state.eural} onChange={(event)=>this.setState({eural:event.target.value})}>
                                            <option>Selecteer een actie</option>
                                            <option value="17 01 01">17 01 01</option>
                                            <option value="17 01 02">17 01 02</option>
                                            <option value="17 01 03">17 01 03</option>
                                            <option value="17 01 07c">17 01 07</option>
                                            <option value="19 12 12">19 12 12</option>
                                            <option value="17 02 01c">17 02 01</option>
                                            <option value="17 04 05c">17 04 05</option>
                                            <option value="17 04 07c">17 04 07</option>
                                            <option value="17 04 09">17 04 09</option>
                                            <option value="17 05 04c">17 05 04</option>
                                            <option value="17 05 08">17 05 08</option>
                                            <option value="17 06 05">17 06 05</option>

                                            
                             
                                            <option value="17 03 01*c">17 03 01</option>
                                            <option value="17 03 02">17 03 02</option>
                                            
                                            <option value="17 03 03*c">17 03 03</option>
                                            <option value="17 09 04c">17 09 04</option>
                                            <option value="17 05 03*">17 05 03</option>
                                            <option value="19 12 06">19 12 06</option>
                                            <option value="20 02 01">20 02 01</option>
                                           
                                        </select>
                                        </span>
                                    <hr/>
                                </div>
                                
                                {/* <div className="request-detail-row">
                                    <span className="request-detail-col-left">De volgende prijsopgave is van toepassing</span>
                                    <span className="request-detail-col-right align-text-right"><input type="text" value={this.state.pricePhone} onChange={(event)=>this.setState({pricePhone:event.target.value})}/></span>
                                    <hr/>
                                </div>
                            *<div className="request-detail-row">
                                    <span className="request-detail-col-left">E-mail</span>
                                    <span className="request-detail-col-right align-text-right"><input type="text" value={this.state.priceMail} onChange={(event)=>this.setState({priceMail:event.target.value})}/></span>
                                    <hr/>
                            </div>*/}
                                <button onClick={()=>this.UpdateASN()} className="dirix-default-button small-button">Opslaan</button>
                                

                            </Col>
                            <Col sm="12" className="requests-block location-block">
                            <h3>Locatie</h3>
          
     
                <MapContainerBackend markers={this.state.markers} lng={this.props.data.data.long} lat={this.props.data.data.lat} />

        
                                
                                

                            </Col>
                        </Row>
                       
                       {/*} <Row>
                            <Col sm="12" className="price-block">
                               
                            </Col>
                        </Row>*/}
                        
                        </Col>
                        
                    <Col sm={8}  className="request-detail-8">
                    <h3>Beoordeling</h3>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Status</span>
                                    <span className="request-detail-col-right align-text-right">{this.state.ratingStatus === 'in progress'?<i class="fa fa-clock"></i>:null }{this.state.ratingStatus=== 'canceled'?<i class="fa fa-times"></i>:null }{this.state.ratingStatus=== 'agreed'?<i class="fa fa-check"></i>:null }</span>
                                    <hr/>
                                </div>
                                
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Gebruikelijke benaming</span>
                                    <span className="request-detail-col-right align-text-right">
                                    <select disabled={this.state.asn == '' ? false: true} value={this.state.nameAsn} onChange={(event)=>this.setState({nameAsn:event.target.value})}>
                                <option >Selecteer een type</option>
                                <option value="Puin">Puin</option>
                                <option value="Hout A/B">Hout A/B</option>
                                <option value="C-hout">C-hout</option>
                                <option value="Ijzerwaren">Ijzerwaren</option>
                                <option value="Asfalt (Teerhoudend)">Asfalt (Teerhoudend)</option>
                                <option value="Asfalt (Teervrij)">Asfalt (Teervrij)</option>
                                <option value="Dakbedekking">Dakbedekking</option>
                                <option value="Bouw & Sloopafval">Bouw & Sloopafval</option>
                                <option value="Grond">Grond</option>
                                <option value="Funderingsmateriaal">Funderingsmateriaal</option>
                                <option value="Betonpuin">Betonpuin</option>
                                <option value="Balast">Balast</option>
                                <option value="Asbest houdend bouwmateriaal">Asbest houdend bouwmateriaal</option>
                                <option value="Asbest houdend metaal">Asbest houdend metaal</option>
                                <option value="Groenafval">Groenafval</option>
                                <option value="Gasbeton">Gasbeton</option>
                                <option value="Gips">Gips</option>
                            </select>
                                    </span>
                                    <hr/>
                                </div>
                                {/*}
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Grondklasse</span>
                                    <span className="request-detail-col-right align-text-right"><input type="text" value={this.state.ratingPhone} onChange={(event)=>this.setState({ratingPhone:event.target.value})}/></span>
                                    <hr/>
                    </div>*/}
                    {this.props.data.data.type == 'Grond'?
                     <div className="request-detail-row">
                    
                    <span className="request-detail-col-left">Grondklasse</span>
                    <span className="request-detail-col-right align-text-right">
                        <select disabled={this.state.asn == '' ? false: true}  value={this.state.groundClassIntern} onChange={(event)=>this.setState({groundClassIntern:event.target.value})}>
                            <option >Selecteer een optie</option>
                            <option value="AW2000">AW2000</option>
                            <option value="Wonen">Wonen</option>
                            <option value="Industrie">Industrie</option>
                            <option value="Onbekend">Onbekend</option>
                            
                        </select>
                    </span>
                    <hr/>
                </div>:null}
                {this.props.data.data.type == 'Grond'?
                    <div className="request-detail-row">
                    
                                    <span className="request-detail-col-left">Ingenomen conform BRL 9335</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <select disabled={this.state.asn == '' ? false: true} value={this.state.brl9335} onChange={(event)=>this.setState({brl9335:event.target.value})}>
                                            <option>Selecteer een optie</option>
                                            <option value="Ja">Ja</option>
                                            <option value="Nee">Nee</option>
                                            
                                        </select>
                                    </span>
                                    <hr/>
                                </div>:null}
                                {this.props.data.data.type == 'Grond'?
                        <div className="request-detail-row">
                    
                    <span className="request-detail-col-left">Pre-kwalificatie</span>
                    <span className="request-detail-col-right align-text-right">
                        <select disabled={this.state.asn == '' ? false: true} value={this.state.pre} onChange={(event)=>this.setState({pre:event.target.value})}>
                            <option >Selecteer een optie</option>
                            <option value="BKK">BKK</option>
                            <option value="VBO">VBO</option>
                            <option value="AP04">AP04</option>
                            
                        </select>
                    </span>
                    <hr/>
                </div>:null}
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Toelichting</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <textarea disabled={this.state.asn == '' ? false: true} className="text-area-backend-form" value={this.state.ratingText} onChange={(event)=>this.setState({ratingText:event.target.value})} />
                                       
                                        </span>
                                    <hr/>
                                </div>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Beoordeling</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <select disabled={this.state.asn == '' ? false: true} value={this.state.rating} onChange={(event)=>this.setState({rating:event.target.value})}>
                                            <option>Selecteer een actie</option>
                                            <option value="goedgekeurd">Goed gekeurd</option>
                                            <option value="afgekeurd">Afgekeurd gekeurd</option>
                                            <option value="In behandeling">In behandeling</option>
                                            
                                        </select>
                                    </span>
                                    <hr/>
                                </div>
                                {this.state.asn == '' ? 
                                <button onClick={()=>this.UpdateRating()} className="dirix-default-button">Opslaan</button>
                                :null}
                            </Col>
                            <Col sm={8}  className="request-detail-8">
                                <h3>Prijs afspraken</h3>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Status</span>
                                    <span className="request-detail-col-right align-text-right">{this.props.data.data.price.check === 'in progress'?<i class="far fa-clock"></i>:null }{this.props.data.data.price.check === 'canceled'?<i class="far fa-times"></i>:null }{this.props.data.data.price.check === 'agreed'?<i class="far fa-check"></i>:null }</span>
                                    <hr/>
                                </div>
                                
                               
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Klant in Navision</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <select disabled={this.state.asn == '' ? false: true} value={this.state.priceNavision} onChange={(event)=>this.setState({priceNavision:event.target.value})}>
                                        <option>Selecteer een actie</option>
                                            <option value="Ja, klant is bekend">Ja, klant is bekend</option>
                                            <option value="Nee, controlleer klant">Nee, Maak klant aan en controlleer klant</option>

                                    
                                        </select>
                                       
                                    </span>
                                    {this.state.priceNavision == 'Nee, controlleer klant' ?  <button onClick={()=>this.sendAdministrationRequest()} className="dirix-default-button administration-request">Stuur administratie e-mail</button>:null}
                                    <hr/>
                                </div>
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Offertenummer of prijsafspraak</span>
                                    <span className="request-detail-col-right align-text-right">
                                    <select disabled={this.state.asn == '' ? false: true} value={this.state.priceName} onChange={(event)=>this.setState({priceName:event.target.value})}>
                                            <option value="Selecteer een optie">Selecteer een optie</option>
                                            <option value="Standaard prijslijst ROS">Standaard prijslijst ROS</option>
                                            <option value="All-in prijslijst Containers">All-in prijslijst Containers</option>
                                            <option value="Anders">Anders, namelijk:</option>
                                    
                                        </select>
                                    </span><br/>
                                    {this.state.priceName == 'Anders'?
                                    <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} placeholder="Offertenummer" type="text" value={this.state.priceNameOfferte} onChange={(event)=>this.setState({priceNameOfferte:event.target.value})}/></span>:
                                    null}
                                    <hr/>
                                </div>
                                {this.state.priceName == 'Anders'?
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Bijlage toevoegen</span>
                                    <span className="request-detail-col-right align-text-right"><input disabled={this.state.asn == '' ? false: true} type="file" onChange={(event) => this.handleUploadCompanyLogo(event)} /></span>
                                    <hr/>
                                </div>
                                :null}
                            {this.state.priceAttachment ?
                            
                                <div className="request-detail-row">
                                    <span className="request-detail-col-left">Bijlage</span>
                                    <span className="request-detail-col-right align-text-right"><a href={this.state.priceAttachment} target="_blank">{this.state.priceAttachment}</a></span>
                                    <hr/>
                                </div>:null}
                                {this.state.tempUrlPrice ?
                            
                            <div className="request-detail-row">
                                <span className="request-detail-col-left">Bijlage</span>
                                <span className="request-detail-col-right align-text-right"><a href={this.state.tempUrlPrice} target="_blank">{this.state.tempUrlPrice}</a></span>
                                <hr/>
                            </div>:null}
                                {/* <div className="request-detail-row">
                                    <span className="request-detail-col-left">De volgende prijsopgave is van toepassing</span>
                                    <span className="request-detail-col-right align-text-right"><input type="text" value={this.state.pricePhone} onChange={(event)=>this.setState({pricePhone:event.target.value})}/></span>
                                    <hr/>
                                </div>
                            *<div className="request-detail-row">
                                    <span className="request-detail-col-left">E-mail</span>
                                    <span className="request-detail-col-right align-text-right"><input type="text" value={this.state.priceMail} onChange={(event)=>this.setState({priceMail:event.target.value})}/></span>
                                    <hr/>
                            </div>*/}
                             <div className="request-detail-row">
                                    <span className="request-detail-col-left">Beoordeling</span>
                                    <span className="request-detail-col-right align-text-right">
                                        <select  disabled={this.state.asn == '' ? false: true} value={this.state.price} onChange={(event)=>this.setState({price:event.target.value})}>
                                            <option>Selecteer een actie</option>
                                            <option value="goedgekeurd">Goed gekeurd</option>
                                            <option value="afgekeurd">Afgekeurd</option>
                                            <option value="In behandeling">In behandeling</option>
                                            
                                        </select>
                                    </span>
                                    <hr/>
                                </div>
                                {this.state.asn == '' ? 
                                <button onClick={()=>this.UpdatePrice()} className="dirix-default-button ">Opslaan</button>
                                :null}
                                
                    </Col>
                    
                </Row>
            </div>
        );
    }
};

export default RequestDetailsBackend;